import {FORM_TYPES} from "./FormTypes";
import {
    transformCostcoData,
    transformCreditKarmaData,
    transformFreeRateUpdateData,
    transformLeadPopsData,
    transformLendingArchData,
    transformLendingTreeData,
    transformMortgageResearchData,
    transformNerdWalletData,
    transformRelcuData,
    transformZillowData
} from "../../../../helpers/transform";

export const transformByTypes = {
    [FORM_TYPES.NERD_WALLET]: transformNerdWalletData,
    [FORM_TYPES.COSTCO]: transformCostcoData,
    [FORM_TYPES.LEAD_POPS]: transformLeadPopsData,
    [FORM_TYPES.CREDIT_KARMA]: transformCreditKarmaData,
    [FORM_TYPES.LENDING_ARCH]: transformLendingArchData,
    [FORM_TYPES.LENDING_TREE]: transformLendingTreeData,
    [FORM_TYPES.MORTGAGE_RESEARCH]: transformMortgageResearchData,
    [FORM_TYPES.FREE_RATE_UPDATE]: transformFreeRateUpdateData,
    [FORM_TYPES.RELCU_PROVIDER]: transformRelcuData,
    [FORM_TYPES.ZILLOW]: transformZillowData,
}