import {FORM_TYPES} from "./FormTypes";
import {items as NerdWallet} from "../../../../schema/NerdWallet.schema.json";
import {items as Costco} from "../../../../schema/Costco.schema.json";
import {items as LeadPops} from "../../../../schema/LeadPops.schema.json";
import {items as CreditKarma} from "../../../../schema/CreditKarma.schema.json";
import {items as LendingArch} from "../../../../schema/LendingArch.schema.json";
import {items as LendingTree} from "../../../../schema/LendingTree.schema.json";
import {items as MortgageResearch} from "../../../../schema/MortgageResearch.schema.json";
import {items as FreeRateUpdate} from "../../../../schema/FreeRateUpdate.schema.json";
import {items as Relcu} from "../../../../schema/Relcu.schema.json";
import {items as Zillow} from "../../../../schema/Zillow.schema.json";

export const schemaByTypes = {
    [FORM_TYPES.NERD_WALLET]: NerdWallet,
    [FORM_TYPES.COSTCO]: Costco,
    [FORM_TYPES.LEAD_POPS]: LeadPops,
    [FORM_TYPES.CREDIT_KARMA]: CreditKarma,
    [FORM_TYPES.LENDING_ARCH]: LendingArch,
    [FORM_TYPES.LENDING_TREE]: LendingTree,
    [FORM_TYPES.MORTGAGE_RESEARCH]: MortgageResearch,
    [FORM_TYPES.FREE_RATE_UPDATE]: FreeRateUpdate,
    [FORM_TYPES.RELCU_PROVIDER]: Relcu,
    [FORM_TYPES.ZILLOW]: Zillow
}