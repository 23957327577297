{
  "$schema": "http://json-schema.org/draft-06/schema#",
  "title": "LendingTree",
  "type": "object",
  "items": {
    "formSchema": {
      "type": "object",
      "properties": {
        "request": {
          "type": "object",
          "properties":{
            "type": {
              "type": "string",
              "enum": [
                "xml",
                "json",
                "urlencoded"
              ]
            }
          }
        },
        "data": {
          "title": "Form Data",
          "type": "object",
          "properties": {
            "ConsumerContactInformation": {
              "type": "object",
              "properties": {
                "FirstName": {
                  "title": "First name",
                  "type": "string"
                },
                "LastName": {
                  "title": "Last name",
                  "type": "string"
                },
                "EmailAddress": {
                  "type": "object",
                  "properties": {
                    "value": {
                      "title": "Email Address",
                      "type": "string",
                      "format": "email"
                    }
                  }
                },
                "ContactPhone": {
                  "type": "object",
                  "properties": {
                    "value": {
                      "title": "Contact Phone",
                      "type": "string",
                      "minLength": 10
                    }
                  }
                }
              }
            },
            "ConsumerProfileInformation": {
              "type": "object",
              "properties": {
                "DateOfBirth": {
                  "title": "Date Of Birth",
                  "type": "string",
                  "format": "date"
                },
                "IsMilitary": {
                  "title": "Is Military",
                  "type": "string",
                  "oneOf": [
                    {
                      "const": "Y",
                      "title": "Yes"
                    },
                    {
                      "const": "N",
                      "title": "No"
                    }
                  ]
                },
                "IsFHAEligible": {
                  "title": "Is FHA Eligible",
                  "type": "string",
                  "enum": [
                    "Yes" ,
                    "No"
                  ]
                },
                "IsVALoan": {
                  "title": "Is VA Loan",
                  "type": "string",
                  "enum": [
                    "Yes" ,
                    "No"
                  ]
                }
              }
            },
            "Credit": {
              "type": "object",
              "properties": {
                "AssignedCreditValue": {
                  "title": "Assigned Credit Value",
                  "type": "number"
                },
                "SelfCreditRating": {
                  "type": "object",
                  "properties": {
                    "Id": {
                      "title": "Self Credit Rating",
                      "type": "string",
                      "oneOf": [
                        {
                          "const": "1",
                          "title": "Excellent"
                        },
                        {
                          "const": "2",
                          "title": "Good"
                        },
                        {
                          "const": "3",
                          "title": "Poor"
                        },
                        {
                          "const": "4",
                          "title": "Very Poor"
                        }
                      ]
                    }
                  }
                }
              }
            },
            "ProductProfileInformation": {
              "type": "object",
              "properties": {
                "AnnualIncome": {
                  "title": "Annual Income",
                  "type": "number"
                },
                "EmploymentStatus": {
                  "type": "object",
                  "properties": {
                    "Id": {
                      "title": "Employment Status",
                      "type": "string",
                      "oneOf": [
                        {
                          "const": "2",
                          "title": "Not Self Employed"
                        },
                        {
                          "const": "3",
                          "title": "Self Employed"
                        },
                        {
                          "const": "4",
                          "title": "Unemployed"
                        }
                      ]
                    }
                  }
                }
              }
            },

            "ProductInformation": {
              "type": "object",
              "properties": {
                "PropertyInformation": {
                  "type": "object",
                  "properties": {
                    "PropertyState": {
                      "title": "State",
                      "type": "string",
                      "oneOf": [
                        {
                          "title": "Alabama",
                          "const": "AL"
                        },
                        {
                          "title": "Alaska",
                          "const": "AK"
                        },
                        {
                          "title": "American Samoa",
                          "const": "AS"
                        },
                        {
                          "title": "Arizona",
                          "const": "AZ"
                        },
                        {
                          "title": "Arkansas",
                          "const": "AR"
                        },
                        {
                          "title": "California",
                          "const": "CA"
                        },
                        {
                          "title": "Colorado",
                          "const": "CO"
                        },
                        {
                          "title": "Connecticut",
                          "const": "CT"
                        },
                        {
                          "title": "Delaware",
                          "const": "DE"
                        },
                        {
                          "title": "District Of Columbia",
                          "const": "DC"
                        },
                        {
                          "title": "Federated States Of Micronesia",
                          "const": "FM"
                        },
                        {
                          "title": "Florida",
                          "const": "FL"
                        },
                        {
                          "title": "Georgia",
                          "const": "GA"
                        },
                        {
                          "title": "Guam",
                          "const": "GU"
                        },
                        {
                          "title": "Hawaii",
                          "const": "HI"
                        },
                        {
                          "title": "Idaho",
                          "const": "ID"
                        },
                        {
                          "title": "Illinois",
                          "const": "IL"
                        },
                        {
                          "title": "Indiana",
                          "const": "IN"
                        },
                        {
                          "title": "Iowa",
                          "const": "IA"
                        },
                        {
                          "title": "Kansas",
                          "const": "KS"
                        },
                        {
                          "title": "Kentucky",
                          "const": "KY"
                        },
                        {
                          "title": "Louisiana",
                          "const": "LA"
                        },
                        {
                          "title": "Maine",
                          "const": "ME"
                        },
                        {
                          "title": "Marshall Islands",
                          "const": "MH"
                        },
                        {
                          "title": "Maryland",
                          "const": "MD"
                        },
                        {
                          "title": "Massachusetts",
                          "const": "MA"
                        },
                        {
                          "title": "Michigan",
                          "const": "MI"
                        },
                        {
                          "title": "Minnesota",
                          "const": "MN"
                        },
                        {
                          "title": "Mississippi",
                          "const": "MS"
                        },
                        {
                          "title": "Missouri",
                          "const": "MO"
                        },
                        {
                          "title": "Montana",
                          "const": "MT"
                        },
                        {
                          "title": "Nebraska",
                          "const": "NE"
                        },
                        {
                          "title": "Nevada",
                          "const": "NV"
                        },
                        {
                          "title": "New Hampshire",
                          "const": "NH"
                        },
                        {
                          "title": "New Jersey",
                          "const": "NJ"
                        },
                        {
                          "title": "New Mexico",
                          "const": "NM"
                        },
                        {
                          "title": "New York",
                          "const": "NY"
                        },
                        {
                          "title": "North Carolina",
                          "const": "NC"
                        },
                        {
                          "title": "North Dakota",
                          "const": "ND"
                        },
                        {
                          "title": "Northern Mariana Islands",
                          "const": "MP"
                        },
                        {
                          "title": "Ohio",
                          "const": "OH"
                        },
                        {
                          "title": "Oklahoma",
                          "const": "OK"
                        },
                        {
                          "title": "Oregon",
                          "const": "OR"
                        },
                        {
                          "title": "Palau",
                          "const": "PW"
                        },
                        {
                          "title": "Pennsylvania",
                          "const": "PA"
                        },
                        {
                          "title": "Puerto Rico",
                          "const": "PR"
                        },
                        {
                          "title": "Rhode Island",
                          "const": "RI"
                        },
                        {
                          "title": "South Carolina",
                          "const": "SC"
                        },
                        {
                          "title": "South Dakota",
                          "const": "SD"
                        },
                        {
                          "title": "Tennessee",
                          "const": "TN"
                        },
                        {
                          "title": "Texas",
                          "const": "TX"
                        },
                        {
                          "title": "Utah",
                          "const": "UT"
                        },
                        {
                          "title": "Vermont",
                          "const": "VT"
                        },
                        {
                          "title": "Virgin Islands",
                          "const": "VI"
                        },
                        {
                          "title": "Virginia",
                          "const": "VA"
                        },
                        {
                          "title": "Washington",
                          "const": "WA"
                        },
                        {
                          "title": "West Virginia",
                          "const": "WV"
                        },
                        {
                          "title": "Wisconsin",
                          "const": "WI"
                        },
                        {
                          "title": "Wyoming",
                          "const": "WY"
                        }
                      ]
                    },
                    "PropertyZip": {
                      "title": "Zip Code",
                      "type": "string",
                      "minLength": 5
                    },
                    "PropertyCity": {
                      "title": "Property City",
                      "type": "string"
                    },
                    "PropertyCounty": {
                      "title": "Property Country",
                      "type": "string"
                    },
                    "IsTarget": {
                      "title": "Is Target",
                      "type": "string",
                      "oneOf": [
                        {
                          "const": "Y",
                          "title": "Yes"
                        },
                        {
                          "const": "N",
                          "title": "No"
                        }
                      ]
                    },
                    "PropertyValue": {
                      "title": "Property Value",
                      "type": "number"
                    },
                    "PropertyType": {
                      "type": "object",
                      "properties": {
                        "Id": {
                          "title": "Property Type",
                          "type": "string",
                          "oneOf": [
                            {
                              "const": "1",
                              "title": "Single Family Detached"
                            },
                            {
                              "const": "3",
                              "title": "Warrantable Condo LT 5 Stories"
                            },
                            {
                              "const": "4",
                              "title": "Non Warrantable Condo LT 5 Stories"
                            },
                            {
                              "const": "5",
                              "title": "2 Unit"
                            },
                            {
                              "const": "6",
                              "title": "Co-Op"
                            },
                            {
                              "const": "10",
                              "title": "Manufactured"
                            },
                            {
                              "const": "0",
                              "title": "Other"
                            }
                          ]
                        }
                      }
                    },
                    "PropertyUse": {
                      "type": "object",
                      "properties": {
                        "Id": {
                          "title": "Property Type",
                          "type": "string",
                          "oneOf": [
                            {
                              "const": "1",
                              "title": "Primary Residence"
                            },
                            {
                              "const": "2",
                              "title": "Second Home"
                            },
                            {
                              "const": "3",
                              "title": "Investment Property"
                            }
                          ]
                        }
                      }
                    }
                  }
                }
              }
            },

            "LoanInformation": {
              "type": "object",
              "properties": {
                "LoanRequestType": {
                  "type": "object",
                  "properties": {
                    "Id": {
                      "title": "Loan Request Type",
                      "type": "string",
                      "oneOf": [
                        {
                          "const": "0",
                          "title": "Refinance"
                        },
                        {
                          "const": "1",
                          "title": "Purchase"
                        }
                      ]
                    }
                  }
                },
                "LoanAmount": {
                  "title": "Loan Amount",
                  "type": "number"
                },
                "FirstMortgageBalance": {
                  "title": "First Mortgage Balance",
                  "type": "number"
                },
                "SecondMortgageBalance": {
                  "title": "Second Mortgage Balance",
                  "type": "number"
                },
                "Term": {
                  "title": "Term",
                  "type": "number"
                },
                "CashOut": {
                  "title": "Cash Out",
                  "type": "number"
                },
                "DownPayment": {
                  "title": "Down Payment",
                  "type": "number"
                }
              }
            },

            "PartnerProfileInformation": {
              "type": "object",
              "properties": {
                "FilterRoutingID": {
                  "title": "Filter Routing ID",
                  "type": "string"
                }
              }
            },
            "TrackingNumber": {
              "title": "Tracking Number",
              "type": "string"
            }
          }
        }
      }
    },
    "uiSchema": {
      "type": "VerticalLayout",
      "elements": [
        {
          "type": "Group",
          "label": "Borrower",
          "elements": [
            {
              "type": "HorizontalLayout",
              "elements": [
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/ConsumerContactInformation/properties/FirstName"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/ConsumerContactInformation/properties/LastName"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/ConsumerContactInformation/properties/EmailAddress/properties/value"
                }
              ]
            },
            {
              "type": "HorizontalLayout",
              "elements": [
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/ConsumerContactInformation/properties/ContactPhone/properties/value"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/ConsumerProfileInformation/properties/DateOfBirth"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/ConsumerProfileInformation/properties/IsMilitary"
                }
              ]
            },
            {
              "type": "HorizontalLayout",
              "elements": [
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/Credit/properties/AssignedCreditValue"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/Credit/properties/SelfCreditRating/properties/Id"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/ProductProfileInformation/properties/AnnualIncome"
                }
              ]
            },
            {
              "type": "HorizontalLayout",
              "elements": [
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/ProductProfileInformation/properties/EmploymentStatus/properties/Id"
                }
              ]
            }
          ]
        },
        {
          "type": "Group",
          "label": "Property",
          "elements": [
            {
              "type": "HorizontalLayout",
              "elements": [
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/ProductInformation/properties/PropertyInformation/properties/PropertyState"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/ProductInformation/properties/PropertyInformation/properties/PropertyZip"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/ProductInformation/properties/PropertyInformation/properties/PropertyCity"
                }
              ]
            },
            {
              "type": "HorizontalLayout",
              "elements": [
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/ProductInformation/properties/PropertyInformation/properties/PropertyCounty"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/ProductInformation/properties/PropertyInformation/properties/IsTarget"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/ProductInformation/properties/PropertyInformation/properties/PropertyValue"
                }
              ]
            },
            {
              "type": "HorizontalLayout",
              "elements": [
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/ProductInformation/properties/PropertyInformation/properties/PropertyType/properties/Id"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/ProductInformation/properties/PropertyInformation/properties/PropertyUse/properties/Id"
                }
              ]
            }
          ]
        },
        {
          "type": "Group",
          "label": "Loan",
          "elements": [
            {
              "type": "HorizontalLayout",
              "elements": [
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/LoanInformation/properties/LoanRequestType/properties/Id"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/LoanInformation/properties/LoanAmount"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/LoanInformation/properties/FirstMortgageBalance"
                }
              ]
            },
            {
              "type": "HorizontalLayout",
              "elements": [
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/LoanInformation/properties/SecondMortgageBalance"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/LoanInformation/properties/Term"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/LoanInformation/properties/CashOut"
                }
              ]
            },
            {
              "type": "HorizontalLayout",
              "elements": [
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/LoanInformation/properties/DownPayment"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/ConsumerProfileInformation/properties/IsFHAEligible"
                },            {
                  "type": "Control",
                  "scope": "#/properties/data/properties/ConsumerProfileInformation/properties/IsVALoan"
                }
              ]
            }
          ]
        },
        {
          "type": "Group",
          "label": "Other",
          "elements": [
            {
              "type": "HorizontalLayout",
              "elements": [
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/PartnerProfileInformation/properties/FilterRoutingID"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/TrackingNumber"
                }
              ]
            }
          ]
        }
      ]
    },
    "initialData": {
      "request": {
        "requestType": "xml"
      },
      "data": {}
    }
  }
}