import React, {memo, useEffect, useState} from 'react'
import {Divider, Grid, IconButton, Tooltip} from "@material-ui/core";
import SpellcheckIcon from "@material-ui/icons/Spellcheck";
import BackspaceIcon from "@material-ui/icons/Backspace";
import SendIcon from "@material-ui/icons/Send";
import {JsonForms} from "@jsonforms/react/lib/JsonForms";
import {materialCells, materialRenderers} from "@jsonforms/material-renderers";
import {useApolloClient} from "@apollo/client";
import {schemaByTypes} from "./constants/SchemaByTypes";
import {queryByTypes} from "./constants/QueryByTypes";
import {transformByTypes} from "./constants/TransformByTypes";
import Tabs from "../../shared/CustomTabs";
import {useFormControl} from "../../../hooks/useFormControl";
import {IFormData} from "../../../types/form";
import JsonView from "../../shared/JsonView";

const Form = ({type}) => {
    const {formSchema, uiSchema, initialData} = schemaByTypes[type]
    const getLeadQuery = queryByTypes[type]
    const transformData = transformByTypes[type]
    const client = useApolloClient();

    const {submitLeadForm} = useFormControl()

    const [formData, setFormData] = useState<IFormData>(initialData)

    useEffect(() => {
        setFormData(initialData)
    }, [type])

    const handleGenerate = async () => {
        const {data} = await client.query({query: getLeadQuery, fetchPolicy: "no-cache"});
        setFormData({...formData, data: transformData(data)})
    }

    const handleClearFormClick = () => {
        setFormData({...formData, data: {}})
    }

    const handleSendLeadClick = async () => {
        await submitLeadForm(formData)
    };

    return (
        <React.Fragment>
            <div style={{height: "100%", display: "flex", flexDirection: "column"}}>
                <div style={{paddingLeft: 15, paddingRight: 15}}>
                    <Grid container justifyContent="space-between" alignItems="center">
                        <Grid item>
                            <strong>Lead Form <small>({type})</small></strong>
                        </Grid>
                        <Grid item>
                            <Tooltip title="Generate random form data">
                                <IconButton aria-label="generate" onClick={handleGenerate}>
                                    <SpellcheckIcon/>
                                </IconButton>
                            </Tooltip>
                            &nbsp;|&nbsp;
                            <Tooltip title="Clear form">
                                <IconButton aria-label="clear form" onClick={handleClearFormClick}>
                                    <BackspaceIcon/>
                                </IconButton>
                            </Tooltip>
                            &nbsp;|&nbsp;
                            <Tooltip title="Submit">
                                <IconButton aria-label="submit" onClick={handleSendLeadClick}>
                                    <SendIcon/>
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </div>
                <Divider/>
                <div style={{paddingLeft: 15, paddingRight: 15, overflow: "auto", flex: 1}}>
                    <Tabs
                        tabs={[
                            'Form',
                            'Json'
                        ]}
                        panels={[
                            <JsonForms
                                schema={formSchema}
                                uischema={uiSchema}
                                data={formData}
                                renderers={materialRenderers}
                                cells={materialCells}
                                onChange={({errors, data}) => setFormData(data)}
                            />,
                            <JsonView
                                data={formData}
                                onChange={({errors, data}) => setFormData(data)}
                            />
                        ]}
                    />
                </div>
            </div>
        </React.Fragment>
    )
}

export default memo(Form)