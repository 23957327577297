import React, {memo} from "react";
import {Grid} from "@material-ui/core";

const EmptyHistory = () => {

    return (
        <Grid
            container
            alignItems="center"
            justifyContent="center"
            style={{height:"100%" }}
        >
            <Grid item>
                No history yet!
            </Grid>
        </Grid>
    )
}

export default memo(EmptyHistory)