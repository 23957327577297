{
  "$schema": "http://json-schema.org/draft-06/schema#",
  "title": "MortgageResearch",
  "type": "object",
  "items": {
    "formSchema": {
      "type": "object",
      "properties": {
        "request": {
          "type": "object",
          "properties":{
            "type": {
              "type": "string",
              "enum": [
                "xml",
                "json",
                "urlencoded"
              ]
            }
          }
        },
        "data": {
          "title": "Form Data",
          "type": "object",
          "properties": {
            "first_name": {
              "title": "First name",
              "type": "string"
            },
            "last_name": {
              "title": "Last name",
              "type": "string"
            },
            "email": {
              "title": "Email Address",
              "type": "string",
              "format": "email"
            },
            "phone_home": {
              "title": "Dey Phone",
              "type": "string",
              "minLength": 10
            },
            "phone_work": {
              "title": "Evening Phone",
              "type": "string",
              "minLength": 10
            },
            "phone_cell": {
              "title": "Evening Phone",
              "type": "string",
              "minLength": 10
            },
            "credit_rating": {
              "title": "Credit Rating",
              "type": "string",
              "enum": [
                "Excellent",
                "Very Good",
                "Good",
                "Fair",
                "Poor"
              ]
            },
            "military": {
              "title": "Military",
              "type": "boolean"
            },

            "property_state": {
              "title": "State",
              "type": "string",
              "oneOf": [
                {
                  "title": "Alabama",
                  "const": "AL"
                },
                {
                  "title": "Alaska",
                  "const": "AK"
                },
                {
                  "title": "American Samoa",
                  "const": "AS"
                },
                {
                  "title": "Arizona",
                  "const": "AZ"
                },
                {
                  "title": "Arkansas",
                  "const": "AR"
                },
                {
                  "title": "California",
                  "const": "CA"
                },
                {
                  "title": "Colorado",
                  "const": "CO"
                },
                {
                  "title": "Connecticut",
                  "const": "CT"
                },
                {
                  "title": "Delaware",
                  "const": "DE"
                },
                {
                  "title": "District Of Columbia",
                  "const": "DC"
                },
                {
                  "title": "Federated States Of Micronesia",
                  "const": "FM"
                },
                {
                  "title": "Florida",
                  "const": "FL"
                },
                {
                  "title": "Georgia",
                  "const": "GA"
                },
                {
                  "title": "Guam",
                  "const": "GU"
                },
                {
                  "title": "Hawaii",
                  "const": "HI"
                },
                {
                  "title": "Idaho",
                  "const": "ID"
                },
                {
                  "title": "Illinois",
                  "const": "IL"
                },
                {
                  "title": "Indiana",
                  "const": "IN"
                },
                {
                  "title": "Iowa",
                  "const": "IA"
                },
                {
                  "title": "Kansas",
                  "const": "KS"
                },
                {
                  "title": "Kentucky",
                  "const": "KY"
                },
                {
                  "title": "Louisiana",
                  "const": "LA"
                },
                {
                  "title": "Maine",
                  "const": "ME"
                },
                {
                  "title": "Marshall Islands",
                  "const": "MH"
                },
                {
                  "title": "Maryland",
                  "const": "MD"
                },
                {
                  "title": "Massachusetts",
                  "const": "MA"
                },
                {
                  "title": "Michigan",
                  "const": "MI"
                },
                {
                  "title": "Minnesota",
                  "const": "MN"
                },
                {
                  "title": "Mississippi",
                  "const": "MS"
                },
                {
                  "title": "Missouri",
                  "const": "MO"
                },
                {
                  "title": "Montana",
                  "const": "MT"
                },
                {
                  "title": "Nebraska",
                  "const": "NE"
                },
                {
                  "title": "Nevada",
                  "const": "NV"
                },
                {
                  "title": "New Hampshire",
                  "const": "NH"
                },
                {
                  "title": "New Jersey",
                  "const": "NJ"
                },
                {
                  "title": "New Mexico",
                  "const": "NM"
                },
                {
                  "title": "New York",
                  "const": "NY"
                },
                {
                  "title": "North Carolina",
                  "const": "NC"
                },
                {
                  "title": "North Dakota",
                  "const": "ND"
                },
                {
                  "title": "Northern Mariana Islands",
                  "const": "MP"
                },
                {
                  "title": "Ohio",
                  "const": "OH"
                },
                {
                  "title": "Oklahoma",
                  "const": "OK"
                },
                {
                  "title": "Oregon",
                  "const": "OR"
                },
                {
                  "title": "Palau",
                  "const": "PW"
                },
                {
                  "title": "Pennsylvania",
                  "const": "PA"
                },
                {
                  "title": "Puerto Rico",
                  "const": "PR"
                },
                {
                  "title": "Rhode Island",
                  "const": "RI"
                },
                {
                  "title": "South Carolina",
                  "const": "SC"
                },
                {
                  "title": "South Dakota",
                  "const": "SD"
                },
                {
                  "title": "Tennessee",
                  "const": "TN"
                },
                {
                  "title": "Texas",
                  "const": "TX"
                },
                {
                  "title": "Utah",
                  "const": "UT"
                },
                {
                  "title": "Vermont",
                  "const": "VT"
                },
                {
                  "title": "Virgin Islands",
                  "const": "VI"
                },
                {
                  "title": "Virginia",
                  "const": "VA"
                },
                {
                  "title": "Washington",
                  "const": "WA"
                },
                {
                  "title": "West Virginia",
                  "const": "WV"
                },
                {
                  "title": "Wisconsin",
                  "const": "WI"
                },
                {
                  "title": "Wyoming",
                  "const": "WY"
                }
              ]
            },
            "property_city": {
              "title": "City",
              "type": "string"
            },
            "property_zip": {
              "title": "Zip Code",
              "type": "string",
              "minLength": 5
            },
            "address": {
              "title": "Address",
              "type": "string"
            },
            "property_type": {
              "title": "Property Type",
              "type": "string",
              "oneOf": [
                {
                  "const": "single",
                  "title": "Single Family"
                },
                {
                  "const": "townhome",
                  "title": "Town Home"
                },
                {
                  "const": "condominium",
                  "title": "Condominium"
                },
                {
                  "const": "multifamily",
                  "title": "Multi Family"
                },
                {
                  "const": "manufactured",
                  "title": "Manufactured Home"
                }
              ]
            },
            "property_use": {
              "title": "Property Use",
              "type": "string",
              "oneOf": [
                {
                  "const": "primary",
                  "title": "Primary Residence"
                },
                {
                  "const": "secondary",
                  "title": "Second / Vacation Home"
                },
                {
                  "const": "investment",
                  "title": "Investment Property"
                }
              ]
            },
            "property_value": {
              "title": "Property Value",
              "type": "number"
            },

            "loan_purpose": {
              "title": "Loan Purpose",
              "type": "string",
              "oneOf": [
                {
                  "const": "purchase",
                  "title": "Purchase"
                },
                {
                  "const": "refinance",
                  "title": "Refinance"
                }
              ]
            },
            "loan_product": {
              "title": "Loan Product",
              "type": "string",
              "oneOf": [
                {
                  "const": "conventional",
                  "title": "Conventional"
                },
                {
                  "const": "fha",
                  "title": "FHA"
                },
                {
                  "const": "va",
                  "title": "VA"
                },
                {
                  "const": "rd",
                  "title": "RD"
                }
              ]
            },
            "loan_amount": {
              "title": "Loan Amount",
              "type": "number"
            },

            "source": {
              "title": "Source",
              "type": "string"
            },
            "lead_price_paid": {
              "title": "Lead Price Paid",
              "type": "string"
            },
            "leadid": {
              "title": "LeadID",
              "type": "string"
            }
          }
        }
      }
    },
    "uiSchema": {
      "type": "VerticalLayout",
      "elements": [
        {
          "type": "Group",
          "label": "Borrower",
          "elements": [
            {
              "type": "HorizontalLayout",
              "elements": [
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/first_name"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/last_name"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/email"
                }
              ]
            },
            {
              "type": "HorizontalLayout",
              "elements": [
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/phone_home"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/phone_work"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/phone_cell"
                }
              ]
            },
            {
              "type": "HorizontalLayout",
              "elements": [
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/credit_rating"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/military"
                }
              ]
            }
          ]
        },
        {
          "type": "Group",
          "label": "Property",
          "elements": [
            {
              "type": "HorizontalLayout",
              "elements": [
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/property_state"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/property_city"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/property_zip"
                }
              ]
            },
            {
              "type": "HorizontalLayout",
              "elements": [
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/address"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/property_type"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/property_use"
                }
              ]
            },
            {
              "type": "HorizontalLayout",
              "elements": [
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/property_value"
                }
              ]
            }
          ]
        },
        {
          "type": "Group",
          "label": "Loan",
          "elements": [
            {
              "type": "HorizontalLayout",
              "elements": [
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/loan_purpose"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/loan_product"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/loan_amount"
                }
              ]
            }
          ]
        },
        {
          "type": "Group",
          "label": "Other",
          "elements": [
            {
              "type": "HorizontalLayout",
              "elements": [
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/source"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/lead_price_paid"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/leadid"
                }
              ]
            }
          ]
        }
      ]
    },
    "initialData": {
      "request": {
        "requestType": "urlencoded"
      },
      "data": {}
    }
  }
}