import {createTheme} from "@material-ui/core/styles";

const theme = createTheme({
    overrides: {
        MuiAppBar: {
            colorPrimary: {
                backgroundColor: "#1976d2",
            },
        },
    },
});
export default createTheme(theme);
