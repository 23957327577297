import {v4 as uuidv4} from "uuid";
import {IFormDataFreeRateUpdate, IRandomData} from "../../types/form";

export const transformFreeRateUpdateData = (original: IRandomData): IFormDataFreeRateUpdate => {
  const {
    person: {
      firstName,
      lastName,
      email,
      phone,
      phone2,
      phone3,
      phone4,
      creditProfile,
      isVeteran
    },
    property: {
      value,
      type,
      used,
      location: {
        state,
        city,
        zip,
        street
      },
      loan: {
        propertyValue,
        purpose,
        amount,
        cashOut,
        downPayment,
        product,
        program,
        currentMortgageBalance,
      }
    }
  } = original

  return {
    FirstName: firstName,
    LastName: lastName,
    Email: email,
    Phone: phone,
    CellPhone: phone2,
    HomePhone: phone3,
    Other_Phone: phone4,
    CreditRating: creditProfile,
    VeteranMilitary: isVeteran ? "Yes" : "No",

    PropertyState: state,
    PropertyCity: city,
    Zip: zip,
    StreetAddress: street,
    PropertyType: {
      "single_family_detached": "Single",
      "townhome": "Town House",
      "detached_condo": "Condo",
      "2_unit": "Town House",
      "3_unit": "Town House",
      "4_unit": "Town House",
      "pud": "Condo",
      "warrantable_condo_lt_5_stories": "Condo",
      "warrantable_condo_5_to_8_stories": "Condo",
      "warrantable_condo_gt_8_stories": "Condo",
      "non_warrantable_condo_lt_5_stories": "Condo",
      "non_warrantable_condo_5_to_8_stories": "Condo",
      "non_warrantable_condo_gt_8_stories": "Condo",
      "condotel_condo_lt_5_stories": "Condo",
      "condotel_condo_5_to_8_stories": "Condo",
      "condotel_condo_gt_8_stories": "Condo",
      "manufactured": "Multi",
      "co_op": "Cooperative"
    }[type],
    PropertyUse: {
      "primary_residence": "primary",
      "second_home": "secondary",
      "investment_property": "investment"
    }[used],
    PropertyValue: value,

    LoanPurpose: {
      "purchase": "purchase",
      "rate_term_refinance": "refinance",
      "cash_out_refinance": "refinance"
    }[purpose],
    DesiredRateType: {
      "10_year_fixed": "Don't Know",
      "15_year_fixed": "15-Yr Fixed",
      "20_year_fixed": "Don't Know",
      "25_year_fixed": "Don't Know",
      "30_year_fixed": "30-Yr Fixed",
      "40_year_fixed": "Don't Know",
      "6_mo_ARM_30_yrs": "ARM",
      "1_year_ARM_30_yrs": "ARM",
      "2_year_ARM_30_yrs": "ARM",
      "3_year_ARM_30_yrs": "ARM",
      "5_year_ARM_30_yrs": "ARM",
      "7_year_ARM_30_yrs": "ARM",
      "10_year_ARM_30_yrs": "ARM",
      "5_year_balloon_30_years": "Don't Know",
      "7_year_balloon_30_years": "Don't Know",
      "10_year_balloon_30_years": "Don't Know",
      "other_products": "Don't Know"
    }[product],
    CurrentFHALoan: {
      "fha": "Yes",
      "conventional": "No",
      "va": "No",
      "rd": "No"
    }[program],
    CurrentVALoan: {
      "fha": "No",
      "conventional": "No",
      "va": "Yes",
      "rd": "No"
    }[program],
    ...(downPayment && {EstimatedDownPayment: Math.round(downPayment * 100 / propertyValue) + "%"}),
    DesiredLoanAmount: amount,
    ...(cashOut && {CashOut: cashOut}),
    ...(currentMortgageBalance && {FirstMortgageBalance: currentMortgageBalance}),

    RequestId: uuidv4(),
    UniversalLeadid: uuidv4()
  }
}