{
  "$schema": "http://json-schema.org/draft-06/schema#",
  "title": "FreeRateUpdate",
  "type": "object",
  "items": {
    "formSchema": {
      "type": "object",
      "properties": {
        "request": {
          "type": "object",
          "properties":{
            "type": {
              "type": "string",
              "enum": [
                "xml",
                "json",
                "urlencoded"
              ]
            }
          }
        },
        "data": {
          "title": "Form Data",
          "type": "object",
          "properties": {
            "FirstName": {
              "title": "First name",
              "type": "string"
            },
            "LastName": {
              "title": "Last name",
              "type": "string"
            },
            "Email": {
              "title": "Email Address",
              "type": "string",
              "format": "email"
            },
            "Phone": {
              "title": "Phone",
              "type": "string",
              "minLength": 10
            },
            "CellPhone": {
              "title": "Cell Phone",
              "type": "string",
              "minLength": 10
            },
            "HomePhone": {
              "title": "Home Phone",
              "type": "string",
              "minLength": 10
            },
            "Other_Phone": {
              "title": "Other Phone",
              "type": "string",
              "minLength": 10
            },
            "CreditRating": {
              "title": "Credit Rating",
              "type": "string",
              "enum": [
                "Excellent",
                "Very Good",
                "Good",
                "Fair",
                "Poor"
              ]
            },
            "VeteranMilitary": {
              "title": "Veteran Military",
              "type": "string",
              "enum": [
                "Yes",
                "No"
              ]
            },

            "PropertyState": {
              "title": "State",
              "type": "string",
              "oneOf": [
                {
                  "title": "Alabama",
                  "const": "AL"
                },
                {
                  "title": "Alaska",
                  "const": "AK"
                },
                {
                  "title": "American Samoa",
                  "const": "AS"
                },
                {
                  "title": "Arizona",
                  "const": "AZ"
                },
                {
                  "title": "Arkansas",
                  "const": "AR"
                },
                {
                  "title": "California",
                  "const": "CA"
                },
                {
                  "title": "Colorado",
                  "const": "CO"
                },
                {
                  "title": "Connecticut",
                  "const": "CT"
                },
                {
                  "title": "Delaware",
                  "const": "DE"
                },
                {
                  "title": "District Of Columbia",
                  "const": "DC"
                },
                {
                  "title": "Federated States Of Micronesia",
                  "const": "FM"
                },
                {
                  "title": "Florida",
                  "const": "FL"
                },
                {
                  "title": "Georgia",
                  "const": "GA"
                },
                {
                  "title": "Guam",
                  "const": "GU"
                },
                {
                  "title": "Hawaii",
                  "const": "HI"
                },
                {
                  "title": "Idaho",
                  "const": "ID"
                },
                {
                  "title": "Illinois",
                  "const": "IL"
                },
                {
                  "title": "Indiana",
                  "const": "IN"
                },
                {
                  "title": "Iowa",
                  "const": "IA"
                },
                {
                  "title": "Kansas",
                  "const": "KS"
                },
                {
                  "title": "Kentucky",
                  "const": "KY"
                },
                {
                  "title": "Louisiana",
                  "const": "LA"
                },
                {
                  "title": "Maine",
                  "const": "ME"
                },
                {
                  "title": "Marshall Islands",
                  "const": "MH"
                },
                {
                  "title": "Maryland",
                  "const": "MD"
                },
                {
                  "title": "Massachusetts",
                  "const": "MA"
                },
                {
                  "title": "Michigan",
                  "const": "MI"
                },
                {
                  "title": "Minnesota",
                  "const": "MN"
                },
                {
                  "title": "Mississippi",
                  "const": "MS"
                },
                {
                  "title": "Missouri",
                  "const": "MO"
                },
                {
                  "title": "Montana",
                  "const": "MT"
                },
                {
                  "title": "Nebraska",
                  "const": "NE"
                },
                {
                  "title": "Nevada",
                  "const": "NV"
                },
                {
                  "title": "New Hampshire",
                  "const": "NH"
                },
                {
                  "title": "New Jersey",
                  "const": "NJ"
                },
                {
                  "title": "New Mexico",
                  "const": "NM"
                },
                {
                  "title": "New York",
                  "const": "NY"
                },
                {
                  "title": "North Carolina",
                  "const": "NC"
                },
                {
                  "title": "North Dakota",
                  "const": "ND"
                },
                {
                  "title": "Northern Mariana Islands",
                  "const": "MP"
                },
                {
                  "title": "Ohio",
                  "const": "OH"
                },
                {
                  "title": "Oklahoma",
                  "const": "OK"
                },
                {
                  "title": "Oregon",
                  "const": "OR"
                },
                {
                  "title": "Palau",
                  "const": "PW"
                },
                {
                  "title": "Pennsylvania",
                  "const": "PA"
                },
                {
                  "title": "Puerto Rico",
                  "const": "PR"
                },
                {
                  "title": "Rhode Island",
                  "const": "RI"
                },
                {
                  "title": "South Carolina",
                  "const": "SC"
                },
                {
                  "title": "South Dakota",
                  "const": "SD"
                },
                {
                  "title": "Tennessee",
                  "const": "TN"
                },
                {
                  "title": "Texas",
                  "const": "TX"
                },
                {
                  "title": "Utah",
                  "const": "UT"
                },
                {
                  "title": "Vermont",
                  "const": "VT"
                },
                {
                  "title": "Virgin Islands",
                  "const": "VI"
                },
                {
                  "title": "Virginia",
                  "const": "VA"
                },
                {
                  "title": "Washington",
                  "const": "WA"
                },
                {
                  "title": "West Virginia",
                  "const": "WV"
                },
                {
                  "title": "Wisconsin",
                  "const": "WI"
                },
                {
                  "title": "Wyoming",
                  "const": "WY"
                }
              ]
            },
            "PropertyCity": {
              "title": "City",
              "type": "string"
            },
            "Zip": {
              "title": "Zip Code",
              "type": "string",
              "minLength": 5
            },
            "StreetAddress": {
              "title": "Street Address",
              "type": "string"
            },
            "PropertyType": {
              "title": "Property Type",
              "type": "string",
              "enum": [
                "Single",
                "Multi",
                "Condo",
                "Town House",
                "Cooperative"
              ]
            },
            "PropertyUse": {
              "title": "Property Use",
              "type": "string",
              "oneOf": [
                {
                  "const": "primary",
                  "title": "Primary Residence"
                },
                {
                  "const": "secondary",
                  "title": "Second / Vacation Home"
                },
                {
                  "const": "investment",
                  "title": "Investment Property"
                }
              ]
            },
            "PropertyValue": {
              "title": "Property Value",
              "type": "number"
            },
            "NewHomeValue": {
              "title": "New Home Value",
              "type": "number"
            },

            "LoanPurpose": {
              "title": "Loan Purpose",
              "type": "string",
              "oneOf": [
                {
                  "const": "purchase",
                  "title": "Purchase"
                },
                {
                  "const": "refinance",
                  "title": "Refinance"
                }
              ]
            },
            "DesiredRateType" : {
              "title": "Desired Rate Type",
              "type": "string",
              "enum": [
                "30-Yr Fixed",
                "15-Yr Fixed",
                "ARM",
                "Don't Know"
              ]
            },
            "RateType1" : {
              "title": "Rate Type",
              "type": "string",
              "enum": [
                "30-Yr Fixed",
                "15-Yr Fixed",
                "ARM"
              ]
            },
            "CurrentFHALoan" : {
              "title": "Current FHA Loan",
              "type": "string",
              "enum": [
                "Yes",
                "No"
              ]
            },
            "CurrentVALoan" : {
              "title": "Current VA Loan",
              "type": "string",
              "enum": [
                "Yes",
                "No"
              ]
            },
            "EstimatedDownPayment": {
              "title": "Estimated Down Payment",
              "type": "string"
            },
            "DesiredLoanAmount": {
              "title": "Desired Loan Amount",
              "type": "number"
            },
            "CashOut": {
              "title": "Cash Out",
              "type": "number"
            },
            "FirstMortgageBalance": {
              "title": "First Mortgage Balance",
              "type": "number"
            },

            "RequestId": {
              "title": "RequestID",
              "type": "string"
            },
            "UniversalLeadid": {
              "title": "Universal LeadID",
              "type": "string"
            }
          }
        }
      }
    },
    "uiSchema": {
      "type": "VerticalLayout",
      "elements": [
        {
          "type": "Group",
          "label": "Borrower",
          "elements": [
            {
              "type": "HorizontalLayout",
              "elements": [
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/FirstName"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/LastName"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/Email"
                }
              ]
            },
            {
              "type": "HorizontalLayout",
              "elements": [
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/Phone"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/CellPhone"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/HomePhone"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/Other_Phone"
                }
              ]
            },
            {
              "type": "HorizontalLayout",
              "elements": [
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/CreditRating"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/VeteranMilitary"
                }
              ]
            }
          ]
        },
        {
          "type": "Group",
          "label": "Property",
          "elements": [
            {
              "type": "HorizontalLayout",
              "elements": [
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/PropertyState"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/PropertyCity"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/Zip"
                }
              ]
            },
            {
              "type": "HorizontalLayout",
              "elements": [
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/StreetAddress"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/PropertyType"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/PropertyUse"
                }
              ]
            },
            {
              "type": "HorizontalLayout",
              "elements": [
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/PropertyValue"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/NewHomeValue"
                }
              ]
            }
          ]
        },
        {
          "type": "Group",
          "label": "Loan",
          "elements": [
            {
              "type": "HorizontalLayout",
              "elements": [
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/LoanPurpose"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/DesiredRateType"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/RateType1"
                }
              ]
            },
            {
              "type": "HorizontalLayout",
              "elements": [
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/CurrentFHALoan"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/CurrentVALoan"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/EstimatedDownPayment"
                }
              ]
            },
            {
              "type": "HorizontalLayout",
              "elements": [
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/DesiredLoanAmount"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/CashOut"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/FirstMortgageBalance"
                }
              ]
            }
          ]
        },
        {
          "type": "Group",
          "label": "Other",
          "elements": [
            {
              "type": "HorizontalLayout",
              "elements": [
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/RequestId"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/UniversalLeadid"
                }
              ]
            }
          ]
        }
      ]
    },
    "initialData": {
      "request": {
        "requestType": "urlencoded"
      },
      "data": {}
    }
  }
}