import {gql} from "@apollo/client";

export const GET_RANDOM_ZILLOW = gql`
    query {
        person {
            firstName
            lastName
            email
            phone
        }
    }
`;