import {gql} from "@apollo/client";

export const GET_RANDOM_FREE_RATE_UPDATE = gql`
    query {
        person {
            firstName
            lastName
            email
            phone
            phone2
            phone3
            phone4
            creditProfile
            isVeteran
        }
        property {
            value
            type
            used
            location {
                state
                city
                zip
                street
            }
            loan {
                propertyValue
                purpose
                amount
                cashOut
                downPayment
                product
                program
                currentMortgageBalance
            }
        }
    }
`;