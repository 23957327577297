import React, {memo} from 'react';
import Toolbar from "@material-ui/core/Toolbar";
import {Chip, Grid, IconButton} from "@material-ui/core";
import MenuEnvironment from "./MenuEnvironment";
import MenuSourceID from "./MenuSourceID";
import AppBar from "@material-ui/core/AppBar";
import SettingsIcon from '@material-ui/icons/Settings';
import HomeIcon from '@material-ui/icons/Home';
import {Link} from "react-router-dom";
import {useSelectedSources} from "../../hooks/useApp";
import {useLocation} from 'react-router-dom'
import _ from "lodash";

const MainHeader = () => {
    const [selectedSourceOption] = useSelectedSources()
    const {pathname} = useLocation();

    return (
        <AppBar position="static">
            <Toolbar>
                <Grid container alignItems="center" spacing={2}>
                    <Grid item>
                        <MenuEnvironment/>
                    </Grid>
                    <Grid item>
                        <MenuSourceID/>
                    </Grid>
                    <Grid item>
                        {!_.isEmpty(selectedSourceOption) &&
                        <Chip label={`SourceID - ${selectedSourceOption.objectId}`} size="small"/>}
                    </Grid>
                </Grid>

                {pathname === "/settings" ? (
                    <Link to="/" style={{color: "inherit"}}>
                        <IconButton color="inherit" edge="start" aria-label="home">
                            <HomeIcon/>
                        </IconButton>
                    </Link>
                ) : (
                    <Link to="/settings" style={{color: "inherit"}}>
                        <IconButton color="inherit" edge="start" aria-label="settings">
                            <SettingsIcon/>
                        </IconButton>
                    </Link>
                )}
            </Toolbar>
        </AppBar>
    )
}

export default memo(MainHeader)