{
  "$schema": "http://json-schema.org/draft-06/schema#",
  "title": "CreditKarma",
  "type": "object",
  "items": {
    "formSchema": {
      "type": "object",
      "properties": {
        "request": {
          "type": "object",
          "properties":{
            "type": {
              "type": "string",
              "enum": [
                "xml",
                "json",
                "urlencoded"
              ]
            }
          }
        },
        "data": {
          "title": "Form Data",
          "type": "object",
          "properties": {
            "firstName": {
              "title": "First name",
              "type": "string"
            },
            "lastName": {
              "title": "Last name",
              "type": "string"
            },
            "email": {
              "title": "Email Address",
              "type": "string",
              "format": "email"
            },
            "dayPhone": {
              "title": "Dey Phone",
              "type": "string",
              "minLength": 10
            },
            "eveningPhone": {
              "title": "Evening Phone",
              "type": "string",
              "minLength": 10
            },
            "creditProfile": {
              "title": "Credit Profile",
              "type": "string",
              "autocomplete":  true,
              "enum": [
                "Excellent",
                "Good",
                "Very Good",
                "Fair",
                "Poor"
              ]
            },
            "servedInMilitary": {
              "title": "Served In Military",
              "type": "string",
              "enum": [
                "yes",
                "no"
              ]
            },
            "workingWithRealEstate": {
              "title": "Working With RealEstate",
              "type": "boolean"
            },

            "propertyState": {
              "title": "State",
              "type": "string",
              "oneOf": [
                {
                  "title": "Alabama",
                  "const": "AL"
                },
                {
                  "title": "Alaska",
                  "const": "AK"
                },
                {
                  "title": "American Samoa",
                  "const": "AS"
                },
                {
                  "title": "Arizona",
                  "const": "AZ"
                },
                {
                  "title": "Arkansas",
                  "const": "AR"
                },
                {
                  "title": "California",
                  "const": "CA"
                },
                {
                  "title": "Colorado",
                  "const": "CO"
                },
                {
                  "title": "Connecticut",
                  "const": "CT"
                },
                {
                  "title": "Delaware",
                  "const": "DE"
                },
                {
                  "title": "District Of Columbia",
                  "const": "DC"
                },
                {
                  "title": "Federated States Of Micronesia",
                  "const": "FM"
                },
                {
                  "title": "Florida",
                  "const": "FL"
                },
                {
                  "title": "Georgia",
                  "const": "GA"
                },
                {
                  "title": "Guam",
                  "const": "GU"
                },
                {
                  "title": "Hawaii",
                  "const": "HI"
                },
                {
                  "title": "Idaho",
                  "const": "ID"
                },
                {
                  "title": "Illinois",
                  "const": "IL"
                },
                {
                  "title": "Indiana",
                  "const": "IN"
                },
                {
                  "title": "Iowa",
                  "const": "IA"
                },
                {
                  "title": "Kansas",
                  "const": "KS"
                },
                {
                  "title": "Kentucky",
                  "const": "KY"
                },
                {
                  "title": "Louisiana",
                  "const": "LA"
                },
                {
                  "title": "Maine",
                  "const": "ME"
                },
                {
                  "title": "Marshall Islands",
                  "const": "MH"
                },
                {
                  "title": "Maryland",
                  "const": "MD"
                },
                {
                  "title": "Massachusetts",
                  "const": "MA"
                },
                {
                  "title": "Michigan",
                  "const": "MI"
                },
                {
                  "title": "Minnesota",
                  "const": "MN"
                },
                {
                  "title": "Mississippi",
                  "const": "MS"
                },
                {
                  "title": "Missouri",
                  "const": "MO"
                },
                {
                  "title": "Montana",
                  "const": "MT"
                },
                {
                  "title": "Nebraska",
                  "const": "NE"
                },
                {
                  "title": "Nevada",
                  "const": "NV"
                },
                {
                  "title": "New Hampshire",
                  "const": "NH"
                },
                {
                  "title": "New Jersey",
                  "const": "NJ"
                },
                {
                  "title": "New Mexico",
                  "const": "NM"
                },
                {
                  "title": "New York",
                  "const": "NY"
                },
                {
                  "title": "North Carolina",
                  "const": "NC"
                },
                {
                  "title": "North Dakota",
                  "const": "ND"
                },
                {
                  "title": "Northern Mariana Islands",
                  "const": "MP"
                },
                {
                  "title": "Ohio",
                  "const": "OH"
                },
                {
                  "title": "Oklahoma",
                  "const": "OK"
                },
                {
                  "title": "Oregon",
                  "const": "OR"
                },
                {
                  "title": "Palau",
                  "const": "PW"
                },
                {
                  "title": "Pennsylvania",
                  "const": "PA"
                },
                {
                  "title": "Puerto Rico",
                  "const": "PR"
                },
                {
                  "title": "Rhode Island",
                  "const": "RI"
                },
                {
                  "title": "South Carolina",
                  "const": "SC"
                },
                {
                  "title": "South Dakota",
                  "const": "SD"
                },
                {
                  "title": "Tennessee",
                  "const": "TN"
                },
                {
                  "title": "Texas",
                  "const": "TX"
                },
                {
                  "title": "Utah",
                  "const": "UT"
                },
                {
                  "title": "Vermont",
                  "const": "VT"
                },
                {
                  "title": "Virgin Islands",
                  "const": "VI"
                },
                {
                  "title": "Virginia",
                  "const": "VA"
                },
                {
                  "title": "Washington",
                  "const": "WA"
                },
                {
                  "title": "West Virginia",
                  "const": "WV"
                },
                {
                  "title": "Wisconsin",
                  "const": "WI"
                },
                {
                  "title": "Wyoming",
                  "const": "WY"
                }
              ]
            },
            "propertyZipCode": {
              "title": "Zip Code",
              "type": "string",
              "minLength": 5
            },
            "propertyType": {
              "title": "Property Type",
              "type": "string",
              "enum": [
                "Single Family" ,
                "Town Home" ,
                "Condominium" ,
                "Multi Family" ,
                "Co-Op" ,
                "Manufactured Home"
              ]
            },
            "propertyUse": {
              "title": "Intended Property Use",
              "type": "string",
              "enum": [
                "Primary Residence" ,
                "Second / Vacation Home" ,
                "Investment Property"
              ]
            },
            "homeValue": {
              "title": "Home Value",
              "type": "number"
            },

            "loanPurpose": {
              "title": "Loan Purpose",
              "type": "string",
              "enum": [
                "Purchase" ,
                "Refinance"
              ]
            },
            "loanTerm" : {
              "title": "Loan Term",
              "type": "string",
              "enum": [
                "1" ,
                "2" ,
                "3" ,
                "5" ,
                "7" ,
                "10" ,
                "15" ,
                "20" ,
                "30"
              ]
            },
            "amortizationType" : {
              "title": "Amortization Type",
              "type": "string",
              "enum": [
                "Fixed" ,
                "Adjustable"
              ]
            },
            "loanType": {
              "title": "Loan Type",
              "type": "string",
              "enum": [
                "FHA",
                "Conventional",
                "VA",
                "Jumbo"
              ]
            },
            "loanAmount": {
              "title": "Loan Amount",
              "type": "number"
            },
            "cashOutAmount": {
              "title": "Cash Out",
              "type": "number"
            },

            "interestRate": {
              "title": "interest Rate",
              "type": "string"
            },
            "homeBuyingProcessStatus": {
              "title": "Home Buying Process Status",
              "type": "string",
              "enum": [
                "Researching",
                "SignedAgreement",
                "OpenHouse",
                "MakingOffers",
                "NotSure"
              ]
            },
            "rate": {
              "title": "Rate",
              "type": "string"
            },
            "refId": {
              "title": "RefId",
              "type": "string"
            }
          }
        }
      }
    },
    "uiSchema": {
      "type": "VerticalLayout",
      "elements": [
        {
          "type": "Group",
          "label": "Borrower",
          "elements": [
            {
              "type": "HorizontalLayout",
              "elements": [
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/firstName"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/lastName"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/email"
                }
              ]
            },
            {
              "type": "HorizontalLayout",
              "elements": [
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/dayPhone"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/eveningPhone"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/creditProfile"
                }
              ]
            },
            {
              "type": "HorizontalLayout",
              "elements": [
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/servedInMilitary"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/workingWithRealEstate"
                }
              ]
            }
          ]
        },
        {
          "type": "Group",
          "label": "Property",
          "elements": [
            {
              "type": "HorizontalLayout",
              "elements": [
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/propertyState"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/propertyZipCode"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/propertyType"
                }
              ]
            },
            {
              "type": "HorizontalLayout",
              "elements": [
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/propertyUse"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/homeValue"
                }
              ]
            }
          ]
        },
        {
          "type": "Group",
          "label": "Loan",
          "elements": [
            {
              "type": "HorizontalLayout",
              "elements": [
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/loanPurpose"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/loanTerm"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/amortizationType"
                }
              ]
            },
            {
              "type": "HorizontalLayout",
              "elements": [
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/loanType"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/loanAmount"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/cashOutAmount"
                }
              ]
            }
          ]
        },
        {
          "type": "Group",
          "label": "Other",
          "elements": [
            {
              "type": "HorizontalLayout",
              "elements": [
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/interestRate"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/homeBuyingProcessStatus"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/rate"
                }
              ]
            },
            {
              "type": "HorizontalLayout",
              "elements": [
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/refId"
                }
              ]
            }
          ]
        }
      ]
    },
    "initialData": {
      "request": {
        "requestType": "urlencoded"
      },
      "data": {}
    }
  }
}
