{
  "$schema": "http://json-schema.org/draft-06/schema#",
  "title": "Relcu",
  "type": "object",
  "items": {
    "formSchema": {
      "type": "object",
      "properties": {
        "request": {
          "type": "object",
          "properties": {
            "type": {
              "type": "string",
              "enum": [
                "xml",
                "json",
                "urlencoded"
              ]
            }
          }
        },
        "data": {
          "title": "Form Data",
          "type": "object",
          "properties": {
            "borrowerFirstName": {
              "title": "Borrower First name",
              "type": "string"
            },
            "borrowerLastName": {
              "title": "Borrower Last name",
              "type": "string"
            },
            "borrowerEmail": {
              "title": "Borrower Email",
              "type": "string",
              "format": "email"
            },
            "borrowerPhone": {
              "title": "Borrower Phone",
              "type": "string",
              "minLength": 10
            },
            "borrowerMobilePhone": {
              "title": "Borrower Mobile Phone",
              "type": "string",
              "minLength": 10
            },
            "borrowerHomePhone": {
              "title": "Borrower Home Phone",
              "type": "string",
              "minLength": 10
            },
            "borrowerWorkPhone": {
              "title": "Borrower Work Phone",
              "type": "string",
              "minLength": 10
            },
            "borrowerCreditRating": {
              "title": "Borrower Credit Rating",
              "type": "string",
              "oneOf": [
                {
                  "title": "Excellent",
                  "const": "excellent"
                },
                {
                  "title": "Very Good",
                  "const": "very_good"
                },
                {
                  "title": "Good",
                  "const": "good"
                },
                {
                  "title": "Poor",
                  "const": "poor"
                },
                {
                  "title": "Very Poor",
                  "const": "very_poor"
                }
              ]
            },
            "borrowerCreditScore": {
              "title": "Borrower Credit Score",
              "type": "number"
            },
            "borrowerEmploymentStatus": {
              "title": "Borrower Employment Status",
              "type": "string",
              "oneOf": [
                {
                  "title": "Self-Employed",
                  "const": "self_employed"
                },
                {
                  "title": "Not Self-Employed",
                  "const": "not_self_employed"
                },
                {
                  "title": "Unemployed",
                  "const": "unemployed"
                }
              ]
            },
            "borrowerMonthlyIncome": {
              "title": "Borrower Monthly Income",
              "type": "number"
            },
            "borrowerIsVeteran": {
              "title": "Borrower Is Veteran",
              "type": "boolean"
            },
            "coBorrowerFirstName": {
              "title": "Co Borrower First name",
              "type": "string"
            },
            "coBorrowerLastName": {
              "title": "Co Borrower Last name",
              "type": "string"
            },
            "coBorrowerEmail": {
              "title": "Co Borrower Email",
              "type": "string",
              "format": "email"
            },
            "coBorrowerMobilePhone": {
              "title": "Co Borrower Mobile Phone",
              "type": "string",
              "minLength": 10
            },
            "coBorrowerHomePhone": {
              "title": "Co Borrower Home Phone",
              "type": "string",
              "minLength": 10
            },
            "coBorrowerWorkPhone": {
              "title": "Co Borrower Work Phone",
              "type": "string",
              "minLength": 10
            },
            "coBorrowerCreditRating": {
              "title": "Co Borrower Credit Rating",
              "type": "string",
              "oneOf": [
                {
                  "title": "Very Poor",
                  "const": "very_poor"
                },
                {
                  "title": "Poor",
                  "const": "poor"
                },
                {
                  "title": "Good",
                  "const": "good"
                },
                {
                  "title": "Very Good",
                  "const": "very_good"
                },
                {
                  "title": "Excellent",
                  "const": "excellent"
                }
              ]
            },
            "coBorrowerCreditScore": {
              "title": "Co Borrower Credit Score",
              "type": "number"
            },
            "coBorrowerEmploymentStatus": {
              "title": "Co Borrower Employment Status",
              "type": "string",
              "oneOf": [
                {
                  "title": "Self-Employed",
                  "const": "self_employed"
                },
                {
                  "title": "Not Self-Employed",
                  "const": "not_self_employed"
                },
                {
                  "title": "Unemployed",
                  "const": "unemployed"
                }
              ]
            },
            "coBorrowerMonthlyIncome": {
              "title": "Co Borrower Monthly Income",
              "type": "number"
            },
            "coBorrowerIsVeteran": {
              "title": "Co Borrower Is Veteran",
              "type": "boolean"
            },
            "propertyState": {
              "title": "State",
              "type": "string",
              "oneOf": [
                {
                  "title": "Alabama",
                  "const": "AL"
                },
                {
                  "title": "Alaska",
                  "const": "AK"
                },
                {
                  "title": "American Samoa",
                  "const": "AS"
                },
                {
                  "title": "Arizona",
                  "const": "AZ"
                },
                {
                  "title": "Arkansas",
                  "const": "AR"
                },
                {
                  "title": "California",
                  "const": "CA"
                },
                {
                  "title": "Colorado",
                  "const": "CO"
                },
                {
                  "title": "Connecticut",
                  "const": "CT"
                },
                {
                  "title": "Delaware",
                  "const": "DE"
                },
                {
                  "title": "District Of Columbia",
                  "const": "DC"
                },
                {
                  "title": "Federated States Of Micronesia",
                  "const": "FM"
                },
                {
                  "title": "Florida",
                  "const": "FL"
                },
                {
                  "title": "Georgia",
                  "const": "GA"
                },
                {
                  "title": "Guam",
                  "const": "GU"
                },
                {
                  "title": "Hawaii",
                  "const": "HI"
                },
                {
                  "title": "Idaho",
                  "const": "ID"
                },
                {
                  "title": "Illinois",
                  "const": "IL"
                },
                {
                  "title": "Indiana",
                  "const": "IN"
                },
                {
                  "title": "Iowa",
                  "const": "IA"
                },
                {
                  "title": "Kansas",
                  "const": "KS"
                },
                {
                  "title": "Kentucky",
                  "const": "KY"
                },
                {
                  "title": "Louisiana",
                  "const": "LA"
                },
                {
                  "title": "Maine",
                  "const": "ME"
                },
                {
                  "title": "Marshall Islands",
                  "const": "MH"
                },
                {
                  "title": "Maryland",
                  "const": "MD"
                },
                {
                  "title": "Massachusetts",
                  "const": "MA"
                },
                {
                  "title": "Michigan",
                  "const": "MI"
                },
                {
                  "title": "Minnesota",
                  "const": "MN"
                },
                {
                  "title": "Mississippi",
                  "const": "MS"
                },
                {
                  "title": "Missouri",
                  "const": "MO"
                },
                {
                  "title": "Montana",
                  "const": "MT"
                },
                {
                  "title": "Nebraska",
                  "const": "NE"
                },
                {
                  "title": "Nevada",
                  "const": "NV"
                },
                {
                  "title": "New Hampshire",
                  "const": "NH"
                },
                {
                  "title": "New Jersey",
                  "const": "NJ"
                },
                {
                  "title": "New Mexico",
                  "const": "NM"
                },
                {
                  "title": "New York",
                  "const": "NY"
                },
                {
                  "title": "North Carolina",
                  "const": "NC"
                },
                {
                  "title": "North Dakota",
                  "const": "ND"
                },
                {
                  "title": "Northern Mariana Islands",
                  "const": "MP"
                },
                {
                  "title": "Ohio",
                  "const": "OH"
                },
                {
                  "title": "Oklahoma",
                  "const": "OK"
                },
                {
                  "title": "Oregon",
                  "const": "OR"
                },
                {
                  "title": "Palau",
                  "const": "PW"
                },
                {
                  "title": "Pennsylvania",
                  "const": "PA"
                },
                {
                  "title": "Puerto Rico",
                  "const": "PR"
                },
                {
                  "title": "Rhode Island",
                  "const": "RI"
                },
                {
                  "title": "South Carolina",
                  "const": "SC"
                },
                {
                  "title": "South Dakota",
                  "const": "SD"
                },
                {
                  "title": "Tennessee",
                  "const": "TN"
                },
                {
                  "title": "Texas",
                  "const": "TX"
                },
                {
                  "title": "Utah",
                  "const": "UT"
                },
                {
                  "title": "Vermont",
                  "const": "VT"
                },
                {
                  "title": "Virgin Islands",
                  "const": "VI"
                },
                {
                  "title": "Virginia",
                  "const": "VA"
                },
                {
                  "title": "Washington",
                  "const": "WA"
                },
                {
                  "title": "West Virginia",
                  "const": "WV"
                },
                {
                  "title": "Wisconsin",
                  "const": "WI"
                },
                {
                  "title": "Wyoming",
                  "const": "WY"
                }
              ]
            },
            "propertyZipCode": {
              "title": "Zip Code",
              "type": "string",
              "minLength": 5
            },
            "propertyCity": {
              "title": "City",
              "type": "string"
            },
            "propertyAddress": {
              "title": "Address",
              "type": "string"
            },
            "propertyAddress2": {
              "title": "Address 2",
              "type": "string"
            },
            "propertyInsurance": {
              "title": "Property Insurance",
              "type": "number"
            },
            "propertyTax": {
              "title": "Property Tax",
              "type": "number"
            },
            "propertyType": {
              "title": "Property Type",
              "type": "string",
              "oneOf": [
                {
                  "title": "Single Family",
                  "const": "single_family_detached"
                },
                {
                  "title": "PUD",
                  "const": "pud"
                },
                {
                  "title": "Townhome",
                  "const": "townhome"
                },
                {
                  "title": "Detached Condo",
                  "const": "detached_condo"
                },
                {
                  "title": "2 Unit",
                  "const": "2_unit"
                },
                {
                  "title": "3 Unit",
                  "const": "3_unit"
                },
                {
                  "title": "4 Unit",
                  "const": "4_unit"
                },
                {
                  "title": "Warrantable Condo 5 Stories",
                  "const": "warrantable_condo_lt_5_stories"
                },
                {
                  "title": "Warrantable Condo 5-8 Stories",
                  "const": "warrantable_condo_5_to_8_stories"
                },
                {
                  "title": "Warrantable Condo 8 Stories",
                  "const": "warrantable_condo_gt_8_stories"
                },
                {
                  "title": "Non-warrantable Condo 5 Stories",
                  "const": "non_warrantable_condo_lt_5_stories"
                },
                {
                  "title": "Non-warrantable Condo 5-8 Stories",
                  "const": "non_warrantable_condo_5_to_8_stories"
                },
                {
                  "title": "Non-warrantable Condo 8 Stories",
                  "const": "non_warrantable_condo_gt_8_stories"
                },
                {
                  "title": "Condotel Condo 5 Stories",
                  "const": "condotel_condo_lt_5_stories"
                },
                {
                  "title": "Condotel Condo 5-8 Stories",
                  "const": "condotel_condo_5_to_8_stories"
                },
                {
                  "title": "Condotel Condo 8 Stories",
                  "const": "condotel_condo_gt_8_stories"
                },
                {
                  "title": "Manufactured",
                  "const": "manufactured"
                },
                {
                  "title": "Co Op",
                  "const": "co_op"
                }
              ]
            },
            "propertyUse": {
              "title": "Property Use",
              "type": "string",
              "oneOf": [
                {
                  "title": "Primary Residence",
                  "const": "primary_residence"
                },
                {
                  "title": "Second/Vacation Home",
                  "const": "second_home"
                },
                {
                  "title": "Investment Property",
                  "const": "investment_property"
                }
              ]
            },
            "propertyValue": {
              "title": "Property Value",
              "type": "number"
            },
            "loanProduct": {
              "title": "Loan Product",
              "type": "string",
              "oneOf": [
                {
                  "title": "10 year fixed",
                  "const": "10_year_fixed"
                },
                {
                  "title": "15 year fixed",
                  "const": "15_year_fixed"
                },
                {
                  "title": "20 year fixed",
                  "const": "20_year_fixed"
                },
                {
                  "title": "25 year fixed",
                  "const": "25_year_fixed"
                },
                {
                  "title": "30 year fixed",
                  "const": "30_year_fixed"
                },
                {
                  "title": "40 year fixed",
                  "const": "40_year_fixed"
                },
                {
                  "title": "6 mo. ARM/30 yrs",
                  "const": "6_mo_ARM_30_yrs"
                },
                {
                  "title": "1 year ARM/30 yrs",
                  "const": "1_year_ARM_30_yrs"
                },
                {
                  "title": "2 year ARM/30 yrs",
                  "const": "2_year_ARM_30_yrs"
                },
                {
                  "title": "3 year ARM/30 yrs",
                  "const": "3_year_ARM_30_yrs"
                },
                {
                  "title": "5 year ARM/30 yrs",
                  "const": "5_year_ARM_30_yrs"
                },
                {
                  "title": "7 year ARM/30 yrs",
                  "const": "7_year_ARM_30_yrs"
                },
                {
                  "title": "10 year ARM/30 yrs",
                  "const": "10_year_ARM_30_yrs"
                },
                {
                  "title": "5 year balloon/30 years",
                  "const": "5_year_balloon_30_years"
                },
                {
                  "title": "7 year balloon/30 years",
                  "const": "7_year_balloon_30_years"
                },
                {
                  "title": "10 year balloon/30 years",
                  "const": "10_year_balloon_30_years"
                },
                {
                  "title": "Other products",
                  "const": "other_products"
                }
              ]
            },
            "loanProgram": {
              "title": "Loan Program",
              "type": "string",
              "oneOf": [
                {
                  "const": "fha",
                  "title": "FHA"
                },
                {
                  "const": "conventional",
                  "title": "Conventional"
                },
                {
                  "const": "va",
                  "title": "VA"
                },
                {
                  "const": "rd",
                  "title": "RD"
                }
              ]
            },
            "loanType": {
              "title": "Loan Type",
              "type": "string",
              "oneOf": [
                {
                  "const": "fha",
                  "title": "FHA"
                },
                {
                  "const": "conventional",
                  "title": "Conventional"
                },
                {
                  "const": "va",
                  "title": "VA"
                },
                {
                  "const": "rd",
                  "title": "RD"
                }
              ]
            },
            "loanTerm": {
              "title": "Loan Term",
              "type": "number",
              "enum": [
                480,
                360,
                300,
                240,
                180,
                120
              ]
            },
            "loanAmount": {
              "title": "Loan Amount",
              "type": "number"
            },
            "loanPurpose": {
              "title": "Loan Purpose",
              "type": "string",
              "oneOf": [
                {
                  "const": "purchase",
                  "title": "Purchase"
                },
                {
                  "const": "rate_term_refinance",
                  "title": "Rate-Term Refinance"
                },
                {
                  "const": "cash_out_refinance",
                  "title": "Cash-Out Refinance"
                }
              ]
            },
            "currentMortgageBalance": {
              "title": "Current Mortgage Balance",
              "type": "number"
            },
            "secondLienBalance": {
              "title": "Second Lien Balance",
              "type": "number"
            },
            "firstLienBalance": {
              "title": "First Lien Balance",
              "type": "number"
            },
            "cashOut": {
              "title": "Additional Cash Out",
              "type": "number"
            },
            "secondaryFinancing": {
              "title": "Secondary Financing",
              "type": "string",
              "oneOf": [
                {
                  "const": "none",
                  "title": "None"
                },
                {
                  "const": "subordinate_financing",
                  "title": "Subordinate Financing"
                },
                {
                  "const": "sub_fin_community_second",
                  "title": "Sub Financing Community Second"
                }
              ]
            },
            "firstTimeHomeBuyer": {
              "title": "First Time Home Buyer",
              "type": "boolean"
            },
            "waiveEscrow": {
              "title": "Waive Escrow",
              "type": "boolean"
            },

            "leadStatus": {
              "title": "Status",
              "type": "string"
            },
            "leadSourceCampaign": {
              "type": "string",
              "title": "Source Campaign"
            },
            "leadCreatedAt": {
              "title": "CreatedAt",
              "type": "string",
              "format": "date-time"
            },
            "leadSourceId": {
              "type": "string",
              "title": "SourceId"
            }
          }
        }
      }
    },
    "uiSchema": {
      "type": "VerticalLayout",
      "elements": [
        {
          "type": "Group",
          "label": "Borrower",
          "elements": [
            {
              "type": "HorizontalLayout",
              "elements": [
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/borrowerFirstName"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/borrowerLastName"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/borrowerEmail"
                }
              ]
            },
            {
              "type": "HorizontalLayout",
              "elements": [
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/borrowerPhone"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/borrowerMobilePhone"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/borrowerHomePhone"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/borrowerWorkPhone"
                }
              ]
            },
            {
              "type": "HorizontalLayout",
              "elements": [
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/borrowerCreditRating"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/borrowerCreditScore"
                }
              ]
            },
            {
              "type": "HorizontalLayout",
              "elements": [
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/borrowerEmploymentStatus"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/borrowerMonthlyIncome"
                }
              ]
            },
            {
              "type": "HorizontalLayout",
              "elements": [
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/borrowerIsVeteran"
                }
              ]
            }
          ]
        },
        {
          "type": "Group",
          "label": "Co-Borrower",
          "elements": [
            {
              "type": "HorizontalLayout",
              "elements": [
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/coBorrowerFirstName"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/coBorrowerLastName"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/coBorrowerEmail"
                }
              ]
            },
            {
              "type": "HorizontalLayout",
              "elements": [
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/coBorrowerMobilePhone"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/coBorrowerHomePhone"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/coBorrowerWorkPhone"
                }
              ]
            },
            {
              "type": "HorizontalLayout",
              "elements": [
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/coBorrowerCreditRating"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/coBorrowerCreditScore"
                }
              ]
            },
            {
              "type": "HorizontalLayout",
              "elements": [
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/coBorrowerEmploymentStatus"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/coBorrowerMonthlyIncome"
                }
              ]
            },
            {
              "type": "HorizontalLayout",
              "elements": [
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/coBorrowerIsVeteran"
                }
              ]
            }
          ]
        },
        {
          "type": "Group",
          "label": "Property",
          "elements": [
            {
              "type": "HorizontalLayout",
              "elements": [
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/propertyState"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/propertyZipCode"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/propertyCity"
                }
              ]
            },
            {
              "type": "HorizontalLayout",
              "elements": [
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/propertyAddress"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/propertyAddress2"
                }
              ]
            },
            {
              "type": "HorizontalLayout",
              "elements": [
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/propertyInsurance"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/propertyTax"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/propertyValue"
                }
              ]
            },
            {
              "type": "HorizontalLayout",
              "elements": [
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/propertyType"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/propertyUse"
                }
              ]
            }
          ]
        },
        {
          "type": "Group",
          "label": "Loan",
          "elements": [
            {
              "type": "HorizontalLayout",
              "elements": [
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/loanProduct"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/loanProgram"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/loanType"
                }
              ]
            },
            {
              "type": "HorizontalLayout",
              "elements": [
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/loanTerm"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/loanAmount"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/loanPurpose"
                }
              ]
            },
            {
              "type": "HorizontalLayout",
              "elements": [
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/currentMortgageBalance"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/secondLienBalance"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/firstLienBalance"
                }
              ]
            },
            {
              "type": "HorizontalLayout",
              "elements": [
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/cashOut"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/secondaryFinancing"
                }
              ]
            },
            {
              "type": "HorizontalLayout",
              "elements": [
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/firstTimeHomeBuyer"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/waiveEscrow"
                }
              ]
            }
          ]
        },
        {
          "type": "Group",
          "label": "Other",
          "elements": [
            {
              "type": "HorizontalLayout",
              "elements": [
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/leadStatus"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/leadSourceCampaign"
                }
              ]
            },
            {
              "type": "HorizontalLayout",
              "elements": [
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/leadCreatedAt"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/leadSourceId"
                }
              ]
            }
          ]
        }
      ]
    },
    "initialData": {
      "request": {
        "requestType": "urlencoded"
      },
      "data": {
        "leadStatus": "New",
        "leadSourceCampaign": "Random Generation"
      }
    }
  }
}
