import {gql} from "@apollo/client";

export const GET_RANDOM_COSTCO = gql`
    query {
        person {
            firstName
            lastName
            email
            phone
            creditProfile
            creditScore
            isVeteran
        }
        property {
            value
            type
            used
            location {
                state
                city
                zip
                street
            }
            loan {
                purpose
                amount
                cashOut
                downPayment
                product
                program
                term
                currentMortgageBalance
            }
        }
    }
`;