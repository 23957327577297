import React, {FC, memo} from "react"
import {TransitionGroup} from 'react-transition-group'
import {Collapse, IconButton} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import {Alert, AlertTitle} from "@material-ui/lab";
import {useNotifications} from "../../hooks/useNotification";
import {INotification} from "../../types/notification";


const Notifications: FC = () => {
    const {notifications, removeNotification} = useNotifications()

    return (
        <div
            style={{
                margin: 20,
                position: 'fixed',
                right: 0,
                top: 0,
                width: '100%',
                maxWidth: 700,
                zIndex: 9999,
            }}
        >
            <TransitionGroup>
                {notifications.map((item: INotification) => (
                    <Collapse key={item.id} in={!!item}>
                        <Alert
                            style={{
                                margin: 5,
                                boxShadow: "rgb(0 0 0 / 20%) 0px 3px 3px -2px, rgb(0 0 0 / 14%) 0px 3px 4px 0px, rgb(0 0 0 / 12%) 0px 1px 8px 0px"
                            }}
                            severity={item.type}
                            action={
                                <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={() => removeNotification(item)}
                                >
                                    <CloseIcon fontSize="inherit"/>
                                </IconButton>
                            }
                        >
                            {item.title && <AlertTitle>{item.title}</AlertTitle>}
                            {item.id && <strong>id: <i>{item.id}</i> - </strong>}
                            {item.text}
                        </Alert>
                    </Collapse>
                ))}
            </TransitionGroup>
        </div>
    );
}

export default memo(Notifications)