import React, {memo} from 'react';
import SplitterLayout from "react-splitter-layout";
import useStickyState from "../../hooks/useStickyState";
import useAppBarHeight from "../../hooks/useAppBarHeight";
import LeadForm from "./LeadForm";
import History from "./History";

const MainContainer = () => {
    const [secondaryInitialSize, setSecondaryInitialSize] = useStickyState(null,'secondaryInitialSize')
    const appBarHeight = useAppBarHeight()

    return (
        <div style={{position:"relative", height: `calc(100vh - ${appBarHeight}px)`}}>
            <SplitterLayout
                primaryIndex={0}
                primaryMinSize={400}
                secondaryMinSize={400}
                {...(secondaryInitialSize && {secondaryInitialSize})}
                onSecondaryPaneSizeChange={secondarySize => setSecondaryInitialSize(secondarySize)}
            >
                <LeadForm />
                <History />
            </SplitterLayout>
        </div>
    )
}

export default memo(MainContainer)