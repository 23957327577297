import {v4 as uuidv4} from "uuid";
import {IFormDataRelcu, IRandomData} from "../../types/form";

export const transformRelcuData = (original: IRandomData): IFormDataRelcu => {
  const {
    person: {
      relcu: {
        borrower,
        coBorrower,
      }
    },
    property: {
      value,
      tax,
      insurance,
      type,
      used,
      location: {
        state,
        zip,
        city,
        street
      },
      loan: {
        purpose,
        amount,
        cashOut,
        firstPropertyPurchase,
        product,
        program,
        term,
        type: loanType,
        waiveEscrow,
        secondaryFinancing,
        currentMortgageBalance,
      }
    }
  } = original

  return {
    borrowerFirstName: borrower.firstName,
    borrowerLastName: borrower.lastName,
    borrowerEmail: borrower.email,
    borrowerPhone: borrower.phone,
    borrowerMobilePhone: borrower.phone2,
    borrowerHomePhone: borrower.phone3,
    borrowerWorkPhone: borrower.phone4,
    borrowerCreditRating: relcuCreditRatingByScore(borrower.creditScore),
    borrowerCreditScore: borrower.creditScore,
    borrowerEmploymentStatus: borrower.employmentStatus,
    borrowerMonthlyIncome: borrower.monthlyIncome,
    borrowerIsVeteran: borrower.isVeteran,
    ...(coBorrower && {
      coBorrowerFirstName: coBorrower.firstName,
      coBorrowerLastName: coBorrower.lastName,
      coBorrowerEmail: coBorrower.email,
      coBorrowerMobilePhone: coBorrower.phone,
      coBorrowerHomePhone: coBorrower.phone2,
      coBorrowerWorkPhone: coBorrower.phone3,
      coBorrowerCreditRating: relcuCreditRatingByScore(coBorrower.creditScore),
      coBorrowerCreditScore: coBorrower.creditScore,
      coBorrowerEmploymentStatus: coBorrower.employmentStatus,
      coBorrowerMonthlyIncome: coBorrower.monthlyIncome,
      coBorrowerIsVeteran: coBorrower.isVeteran
    }),

    propertyState: state,
    propertyZipCode: zip,
    propertyCity: city,
    propertyAddress: street,
    propertyInsurance: insurance,
    propertyTax: tax,
    propertyType: type,
    propertyUse: used,
    propertyValue: value,

    loanPurpose: purpose,
    ...(firstPropertyPurchase && {firstTimeHomeBuyer: firstPropertyPurchase}),
    ...(cashOut && {cashOut: cashOut}),
    loanAmount: amount,
    loanProduct: product,
    ...(program && {loanProgram: program}),
    ...(loanType && {loanType: loanType}),
    waiveEscrow: waiveEscrow,
    loanTerm: term,
    secondaryFinancing: secondaryFinancing,
    ...(currentMortgageBalance && {currentMortgageBalance: currentMortgageBalance}),

    leadStatus: "New",
    leadSourceCampaign: "Random Generation",
    leadSourceId: uuidv4(),
    leadCreatedAt: new Date().toISOString()
  }
}

const relcuCreditRatingByScore = (score) => {
  if(score>=781 && score<=850)
    return "excellent"

  if(score>=661 && score<=780)
    return "very_good"

  if(score>=601 && score<=660)
    return "good"

  if(score>=500 && score<=600)
    return "poor"

  if(score>=300 && score<=499)
    return "very_poor"

  return "excellent"
}