import {useContext} from "react";
import {AppContext} from "../components/AppProviders";
import useStickyState from "./useStickyState";
import _ from "lodash";


export const useAppProvider = () => {
    const environmentOptions = useStickyState([], "environment-options")
    const environmentSelected = useStickyState({}, "environment-selected")

    const sourcesOptions = useStickyState([], "sources-options")
    const sourcesSelected = useStickyState({}, "sources-selected")

    return {
        environment: {
            options: environmentOptions,
            selected: environmentSelected
        },
        sources: {
            options: sourcesOptions,
            selected: sourcesSelected
        },
        history: useStickyState([], "history")
    };
}

export const useEnvironmentsList = () => {

    const {
        environment: {
            options: [value, setValue],
        }
    } = useContext(AppContext);

    return [value, setValue];
}

export const useSelectedEnvironment = () => {

    const {
        environment: {
            selected: [value, setValue],
        }
    } = useContext(AppContext);

    return [value, setValue];
}

export const useSourcesList = () => {

    const {
        sources: {
            options: [value, setValue],
        }
    } = useContext(AppContext);

    return [value, setValue];
}

export const useSelectedSources = () => {

    const {
        sources: {
            selected: [value, setValue],
        }
    } = useContext(AppContext);

    return [value, setValue];
}

export const useHistory = () => {
    const {history: [history, setHistory]} = useContext(AppContext);
    const [environment] = useSelectedEnvironment()
    const [source] = useSelectedSources()

    const add = (item) => {
        setHistory([item,...history])
    }
    const remove = (item) => {
        _.remove(history,item)
        setHistory(history)
    }
    const clear = () => {
        setHistory([])
    }

    return {
        data: history,
        actions: {add, remove, clear}
    };
}