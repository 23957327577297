import { OBJtoXML, transformLeadSourcesData } from "../helpers/utils";
import { IFormData }                          from "../types/form";
import { EnvironmentType }                    from "../types/global";
export async function postLeadForm(formData: IFormData, sourcesId: string, env: EnvironmentType): Promise<any> {
  const { request: {  requestType = "urlencoded" }, data } = formData;

  const contentType = {
    urlencoded: "application/x-www-form-urlencoded;charset=UTF-8",
    xml: "application/xml;charset=UTF-8",
    json: "application/json;charset=UTF-8"
  }[ requestType ];

  const requestBody = {
    urlencoded: (new URLSearchParams(Object.entries(data))).toString(),
    xml: OBJtoXML(data),
    json: JSON.stringify(data)
  }[ requestType ];

  const requestData: [RequestInfo, RequestInit] = [
    `${env.hostname}/api/v1/partner/import?CampaignId=${sourcesId}`,
    {
      method: "POST",
      headers: {
        "Authorization": `Basic ${btoa(`${env.username}:${env.password}`)}`,
        ...(contentType && { "Content-Type": contentType })
      },
      body: requestBody
    }
  ];

  return fetch(new Request(...requestData)).then(response => {
    if (response.ok) {
      return response.text();
    }
    return response.text().then(text => {
      throw new Error(text);
    });
  }).then(response => ({ req: requestData, res: response })).catch(err => ({ req: requestData, error: err }));
}

export async function getLeadSources(
  {
    hostname,
    username,
    password
  }: {
    hostname?: string,
    username?: string,
    password?: string
  }
): Promise<any> {
  return fetch(`${hostname}/api/v1/classes/LeadSource`, {
    method: "GET",
    headers: {
      "Content-type": "application/json;charset=UTF-8",
      "Authorization": `Basic ${btoa(`${username}:${password}`)}`
    }
  }).then(response => {
    if (response.ok) {
      return response.json();
    }
    throw new Error("Something went wrong");
  }).then(json => ({
    data: transformLeadSourcesData(json.results)
  })).catch(err => {
    console.error(`Fetch problem: ${err.message}`);
    return {
      error: {
        id: Date.now(),
        type: "error",
        title: "Fetch problem",
        text: err.message
      }
    };
  });
}

