{
  "$schema": "http://json-schema.org/draft-06/schema#",
  "title": "LeadPops",
  "type": "object",
  "items": {
    "formSchema": {
      "type": "object",
      "properties": {
        "request": {
          "type": "object",
          "properties":{
            "type": {
              "type": "string",
              "enum": [
                "xml",
                "json",
                "urlencoded"
              ]
            }
          }
        },
        "data": {
          "title": "Form Data",
          "type": "object",
          "properties": {
            "firstname": {
              "title": "First Name",
              "type": "string"
            },
            "lastname": {
              "title": "Last Name",
              "type": "string"
            },
            "primaryemail": {
              "title": "Email Address",
              "type": "string",
              "format": "email"
            },
            "primaryphone": {
              "title": "Phone Number",
              "type": "string"
            },
            "state": {
              "oneOf": [
                {
                  "title": "Alabama",
                  "const": "AL"
                },
                {
                  "title": "Alaska",
                  "const": "AK"
                },
                {
                  "title": "Arizona",
                  "const": "AZ"
                },
                {
                  "title": "Arkansas",
                  "const": "AR"
                },
                {
                  "title": "California",
                  "const": "CA"
                },
                {
                  "title": "Colorado",
                  "const": "CO"
                },
                {
                  "title": "Connecticut",
                  "const": "CT"
                },
                {
                  "title": "Delaware",
                  "const": "DE"
                },
                {
                  "title": "Florida",
                  "const": "FL"
                },
                {
                  "title": "Georgia",
                  "const": "GA"
                },
                {
                  "title": "Hawaii",
                  "const": "HI"
                },
                {
                  "title": "Idaho",
                  "const": "ID"
                },
                {
                  "title": "Illinois",
                  "const": "IL"
                },
                {
                  "title": "Indiana",
                  "const": "IN"
                },
                {
                  "title": "Iowa",
                  "const": "IA"
                },
                {
                  "title": "Kansas",
                  "const": "KS"
                },
                {
                  "title": "Kentucky",
                  "const": "KY"
                },
                {
                  "title": "Louisiana",
                  "const": "LA"
                },
                {
                  "title": "Maine",
                  "const": "ME"
                },
                {
                  "title": "Maryland",
                  "const": "MD"
                },
                {
                  "title": "Massachusetts",
                  "const": "MA"
                },
                {
                  "title": "Michigan",
                  "const": "MI"
                },
                {
                  "title": "Minnesota",
                  "const": "MN"
                },
                {
                  "title": "Mississippi",
                  "const": "MS"
                },
                {
                  "title": "Missouri",
                  "const": "MO"
                },
                {
                  "title": "Montana",
                  "const": "MT"
                },
                {
                  "title": "Nebraska",
                  "const": "NE"
                },
                {
                  "title": "Nevada",
                  "const": "NV"
                },
                {
                  "title": "New Hampshire",
                  "const": "NH"
                },
                {
                  "title": "New Jersey",
                  "const": "NJ"
                },
                {
                  "title": "New Mexico",
                  "const": "NM"
                },
                {
                  "title": "New York",
                  "const": "NY"
                },
                {
                  "title": "North Carolina",
                  "const": "NC"
                },
                {
                  "title": "North Dakota",
                  "const": "ND"
                },
                {
                  "title": "Ohio",
                  "const": "OH"
                },
                {
                  "title": "Oklahoma",
                  "const": "OK"
                },
                {
                  "title": "Oregon",
                  "const": "OR"
                },
                {
                  "title": "Pennsylvania",
                  "const": "PA"
                },
                {
                  "title": "Rhode Island",
                  "const": "RI"
                },
                {
                  "title": "South Carolina",
                  "const": "SC"
                },
                {
                  "title": "South Dakota",
                  "const": "SD"
                },
                {
                  "title": "Tennessee",
                  "const": "TN"
                },
                {
                  "title": "Texas",
                  "const": "TX"
                },
                {
                  "title": "Utah",
                  "const": "UT"
                },
                {
                  "title": "Vermont",
                  "const": "VT"
                },
                {
                  "title": "Virginia",
                  "const": "VA"
                },
                {
                  "title": "Washington",
                  "const": "WA"
                },
                {
                  "title": "West Virginia",
                  "const": "WV"
                },
                {
                  "title": "Wisconsin",
                  "const": "WI"
                },
                {
                  "title": "Wyoming",
                  "const": "WY"
                }
              ]
            },
            "enteryourzipcode": {
              "title": "Zip Code",
              "type": "string"
            },
            "typeofproperty": {
              "title": "Type of Property",
              "type": "string",
              "enum": [
                "Single Family Home",
                "Townhome",
                "Condominium",
                "Multi-Family Home",
                "Other"
              ]
            },
            "additionalcash": {
              "title": "Additional Cash",
              "type": "integer"
            },
            "typeloan": {
              "title": "Type Loan",
              "type": "string",
              "enum": [
                "Home Purchase",
                "Home Refinance"
              ]
            },
            "propertyusedfor": {
              "title": "Property Used For",
              "type": "string",
              "enum": [
                "Primary Home",
                "Secondary Home",
                "Rental Property"
              ]
            },
            "loanamount": {
              "title": "Loan Amount",
              "type": "integer"
            },
            "firstpropertypurchase": {
              "title": "First Property Purchase",
              "type": "boolean"
            },
            "propertypurchasesituation": {
              "title": "Property Purchase Situation",
              "type": "string"
            },
            "purchaseprice": {
              "title": "Purchase Price",
              "type": "integer"
            },
            "downpayment": {
              "title": "Down Payment",
              "type": "integer"
            },
            "typeofratedesired": {
              "title": "Type of Rate Desired",
              "type": "integer"
            },
            "grossmonthlyincome": {
              "title": "Gross Monthly Income",
              "type": "integer"
            },
            "grossannualincome": {
              "title": "Gross Annual Income",
              "type": "integer"
            },
            "filedbankruptcy": {
              "title": "Filed Bankruptcy",
              "type": "boolean"
            },
            "utmcampaign": {
              "title": "UTM Campaign",
              "type": "string"
            },
            "SourceID": {
              "title": "Source ID",
              "type": "string"
            },
            "workingwithrealtor": {
              "title": "Working with Realtor",
              "type": "boolean"
            },
            "yourcreditprofile": {
              "title": "Your Credit Profile",
              "type": "string",
              "enum": [
                "Excellent",
                "Very Good",
                "Good",
                "Fair",
                "Poor"
              ]
            },
            "showproofofincome": {
              "title": "Show Proof Of Income",
              "type": "string",
              "enum": [
                "YES",
                "NO"
              ]
            },
            "employmentstatus": {
              "title": "Employment Status",
              "type": "string",
              "enum": [
                "Employed",
                "Military",
                "Not Employed",
                "Self Employed",
                "Other"
              ]
            },
            "estimatedhomevalue": {
              "title": "Estimated Home Value",
              "type": "integer"
            },
            "SubID": {
              "title": "Sub ID",
              "type": "string"
            },
            "ClickID": {
              "title": "Click ID",
              "type": "string"
            }
          }
        }
      }
    },
    "uiSchema": {
      "type": "VerticalLayout",
      "elements": [
        {
          "type": "Group",
          "label": "Borrower",
          "elements": [
            {
              "type": "HorizontalLayout",
              "elements": [
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/firstname"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/lastname"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/primaryemail"
                }
              ]
            },
            {
              "type": "HorizontalLayout",
              "elements": [
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/primaryphone"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/grossmonthlyincome"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/grossannualincome"
                }
              ]
            },
            {
              "type": "HorizontalLayout",
              "elements": [
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/yourcreditprofile"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/employmentstatus"
                }
              ]
            },
            {
              "type": "HorizontalLayout",
              "elements": [
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/workingwithrealtor"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/filedbankruptcy"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/firstpropertypurchase"
                }
              ]
            }
          ]
        },
        {
          "type": "Group",
          "label": "Property",
          "elements": [
            {
              "type": "HorizontalLayout",
              "elements": [
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/state"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/enteryourzipcode"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/typeofproperty"
                }
              ]
            },
            {
              "type": "HorizontalLayout",
              "elements": [
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/propertyusedfor"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/estimatedhomevalue"
                }
              ]
            }
          ]
        },
        {
          "type": "Group",
          "label": "Loan",
          "elements": [
            {
              "type": "HorizontalLayout",
              "elements": [
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/typeloan"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/loanamount"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/purchaseprice"
                }
              ]
            },
            {
              "type": "HorizontalLayout",
              "elements": [
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/additionalcash"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/downpayment"
                }
              ]
            }
          ]
        },
        {
          "type": "Group",
          "label": "Other",
          "elements": [
            {
              "type": "HorizontalLayout",
              "elements": [
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/propertypurchasesituation"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/showproofofincome"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/typeofratedesired"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/utmcampaign"
                }
              ]
            },
            {
              "type": "HorizontalLayout",
              "elements": [
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/SourceID"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/SubID"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/ClickID"
                }
              ]
            }
          ]
        }
      ]
    },
    "initialData": {
      "request": {
        "requestType": "urlencoded"
      },
      "data": {
        "utmcampaign": "Random Generation"
      }
    }
  }
}