import React, {FC, Fragment, memo, useState} from "react";
import {Collapse, IconButton, TableCell, TableRow} from "@material-ui/core";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import ReplayIcon from '@material-ui/icons/Replay';
import Tabs from "../../shared/CustomTabs";
import {useFormControl} from "../../../hooks/useFormControl";
import JsonView from "../../shared/JsonView";
import {IHistory} from "../../../types/global";

const HistoryRow:FC<IHistory> = ({environment, source, time, leadForm, request, response}) => {
    const [open, setOpen] = useState(false);
    const {submitLeadForm} = useFormControl()

    return (
        <Fragment>
            <TableRow>
                <TableCell>
                    <IconButton
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell>
                    {environment.name}
                </TableCell>
                <TableCell>
                    {source.provider}
                </TableCell>
                <TableCell>
                    {source.title}
                </TableCell>
                <TableCell>
                    {source.objectId}
                </TableCell>
                <TableCell align="right">
                    <small>{new Date(time).toLocaleDateString()} {new Date(time).toLocaleTimeString()}</small>
                </TableCell>
                <TableCell align="right">
                    <IconButton
                        color="inherit"
                        edge="start"
                        aria-label="Resend"
                        onClick={async ()=>{await submitLeadForm(leadForm,environment,source)}}
                    >
                        <ReplayIcon />
                    </IconButton>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Tabs
                            tabs={[
                                'Lead Form',
                                'Environment',
                                'Source',
                                'Request',
                                'Response'
                            ]}
                            panels={[
                                <JsonView data={leadForm} />,
                                <JsonView data={environment} />,
                                <JsonView data={source} />,
                                <JsonView data={{request}} />,
                                <JsonView data={{response}} />
                            ]}
                        />
                    </Collapse>
                </TableCell>
            </TableRow>
        </Fragment>
    );
}

export default memo(HistoryRow);