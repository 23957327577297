import {useContext, useState} from "react";
import {INotification, NotificationContextType} from "../types/notification";
import {NotificationContext} from "../components/AppProviders";


export const useNotificationProvider = () => {
    const [notifications, setNotification] = useState<INotification[]>([]);

    const addNotification = (notification: INotification): void => {
        setNotification((prev) => [notification,...prev]);
        setTimeout(() => removeNotification(notification), 5000);
    }

    const removeNotification = (item:INotification): void => {
        setNotification((prev) => [...prev.filter((i) => i !== item)])
    }

    return {
        notifications,
        addNotification,
        removeNotification
    };
}

export const useNotifications = () => {

    const { notifications, addNotification, removeNotification } = useContext(NotificationContext) as NotificationContextType

    return {
        notifications,
        addNotification,
        removeNotification
    }
}