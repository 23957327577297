import React, {memo} from "react";
import {Button, Chip, ListItemIcon, ListItemText} from "@material-ui/core";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import {useSelectedEnvironment, useSelectedSources, useSourcesList} from "../../hooks/useApp";
import _ from "lodash";

const MenuSourceID = () => {
    const [sources] = useSourcesList()
    const [environment] = useSelectedEnvironment()

    const options = _.find(sources, {environmentsID: environment.id})?.sourcesData

    const [selectedSourceOption, setSelectedSourceOption] = useSelectedSources()

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);



    if (!options)
        return null


    const handleMenuClick = (item) => {
        setSelectedSourceOption(item)

        setAnchorEl(null);
    };

    return (
        <>
            <Button
                variant="outlined"
                size="small"
                color="inherit"
                onClick={(event: React.MouseEvent<HTMLElement>) => {setAnchorEl(event.currentTarget)}}
                style={{textTransform: 'none'}}
                endIcon={!!anchorEl ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
            >
                {!_.isEmpty(selectedSourceOption) ? selectedSourceOption.title : "Select SourceID"}
            </Button>
            <Menu
                anchorEl={anchorEl}
                open={!!anchorEl}
                onClose={() => setAnchorEl(null)}
            >
                {options.map((item, index) =>
                    <MenuItem
                        key={index}
                        disabled={_.isEqual(selectedSourceOption, item) || !item.provider}
                        selected={_.isEqual(selectedSourceOption, item)}
                        onClick={() => handleMenuClick(item)}
                    >
                        <ListItemText>{item.title}</ListItemText>
                        {item.provider &&
                            <ListItemIcon>
                                <Chip
                                    label={item.provider}
                                    style={{marginLeft: 25}}
                                    color={item.active ? "primary" : "default"}
                                    size="small"
                                    variant="outlined"
                                />
                            </ListItemIcon>
                        }
                    </MenuItem>
                )}
            </Menu>
        </>
    )
}

export default memo(MenuSourceID)