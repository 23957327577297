import React, {memo} from "react";
import MainContainer from "../MainContainer";

const HomePage = () => {

    return (
        <div id="main">
            <MainContainer />
        </div>
    )
}

export default memo(HomePage)