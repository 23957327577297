import React, {memo, forwardRef} from "react";
import {
    Box,
    Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle,
    Divider, FormControl,
    Grid,
    IconButton, InputLabel,
    ListItemIcon,
    ListItemText,
} from "@material-ui/core";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import _ from "lodash";
import {useEnvironmentsList, useSelectedEnvironment, useSelectedSources} from "../../hooks/useApp";


const MenuEnvironment = () => {
    const [environments] = useEnvironmentsList()
    const [selectedEnvironmentOption, setSelectedEnvironmentOption] = useSelectedEnvironment()
    const [selectedSources, setSelectedSources] = useSelectedSources()

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const isMenuOpen = Boolean(anchorEl);

    const handleEnvironmentMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClick = (item) => {
        setSelectedEnvironmentOption(item)
        setSelectedSources({})
        setAnchorEl(null);
    };

    return (
        <>
            <Button
                variant="outlined"
                size="small"
                color="inherit"
                onClick={handleEnvironmentMenuOpen}
                style={{textTransform: 'none'}}
                endIcon={isMenuOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            >
                {!_.isEmpty(selectedEnvironmentOption) ? selectedEnvironmentOption.name : "Select Environment"}
            </Button>
            <Menu
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={isMenuOpen}
                onClose={()=>setAnchorEl(null)}
            >
                {environments.map((item, index) =>
                    <MenuItem
                        key={index}
                        disabled={_.isEqual(selectedEnvironmentOption,item)}
                        selected={_.isEqual(selectedEnvironmentOption,item)}
                        onClick={()=>handleMenuClick(item)}
                    >
                        <ListItemText>
                            {item.name}
                        </ListItemText>
                    </MenuItem>
                )}

                {_.isEmpty(environments) &&
                <div style={{textAlign:"center", padding:25}}>
                  There is no added eny environment.
                </div>
                }
            </Menu>
        </>
    )
}

export default memo(MenuEnvironment)