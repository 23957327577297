import {v4 as uuidv4} from "uuid";
import {IFormDataMortgageResearch, IRandomData} from "../../types/form";

export const transformMortgageResearchData = (original: IRandomData): IFormDataMortgageResearch => {
  const {
    person: {
      id,
      firstName,
      lastName,
      email,
      phone,
      phone2,
      phone3,
      creditProfile,
      isVeteran
    },
    property: {
      value,
      type,
      used,
      location: {
        state,
        city,
        zip,
        street
      },
      loan: {
        purpose,
        amount,
        program
      }
    }
  } = original

  return {
    first_name: firstName,
    last_name: lastName,
    email: email,
    phone_home: phone,
    phone_work: phone2,
    phone_cell: phone3,
    credit_rating: creditProfile,
    military: isVeteran,

    property_state: state,
    property_city: city,
    property_zip: zip,
    address: street,
    property_type: {
      "single_family_detached": "single",
      "townhome": "townhome",
      "detached_condo": "condominium",
      "2_unit": "townhome",
      "3_unit": "townhome",
      "4_unit": "townhome",
      "pud": "townhome",
      "warrantable_condo_lt_5_stories": "condominium",
      "warrantable_condo_5_to_8_stories": "condominium",
      "warrantable_condo_gt_8_stories": "condominium",
      "non_warrantable_condo_lt_5_stories": "condominium",
      "non_warrantable_condo_5_to_8_stories": "condominium",
      "non_warrantable_condo_gt_8_stories": "condominium",
      "condotel_condo_lt_5_stories": "condominium",
      "condotel_condo_5_to_8_stories": "condominium",
      "condotel_condo_gt_8_stories": "condominium",
      "manufactured": "multifamily",
      "co_op": "condominium"
    }[type],
    property_use: {
      "primary_residence": "primary",
      "second_home": "secondary",
      "investment_property": "investment"
    }[used],
    property_value: value,

    loan_purpose: {
      "purchase": "purchase",
      "rate_term_refinance": "refinance",
      "cash_out_refinance": "refinance"
    }[purpose],
    ...(program && {loan_product: program}),
    loan_amount: amount,

    source: uuidv4(),
    leadid: uuidv4()
  }
}