{
  "$schema": "http://json-schema.org/draft-06/schema#",
  "title": "Zillow",
  "type": "object",
  "items": {
    "formSchema": {
      "type": "object",
      "properties": {
        "request": {
          "type": "object",
          "properties":{
            "type": {
              "type": "string",
              "enum": [
                "xml",
                "json",
                "urlencoded"
              ]
            }
          }
        },
        "data": {
          "title": "Form Data",
          "type": "object",
          "properties": {
            "sender": {
              "type": "object",
              "properties": {
                "firstName": {
                  "title": "First name",
                  "type": "string"
                },
                "lastName": {
                  "title": "Last name",
                  "type": "string"
                },
                "emailAddress": {
                  "title": "Email Address",
                  "type": "string",
                  "format": "email"
                },
                "phoneNumber": {
                  "title": "Dey Phone",
                  "type": "string",
                  "minLength": 10
                }
              }
            }
          }
        }
      }
    },
    "uiSchema": {
      "type": "VerticalLayout",
      "elements": [
        {
          "type": "Group",
          "label": "Borrower",
          "elements": [
            {
              "type": "HorizontalLayout",
              "elements": [
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/sender/properties/firstName"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/sender/properties/lastName"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/sender/properties/emailAddress"
                }
              ]
            },
            {
              "type": "HorizontalLayout",
              "elements": [
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/sender/properties/phoneNumber"
                }
              ]
            }
          ]
        }
      ]
    },
    "initialData": {
      "request": {
        "requestType": "xml"
      },
      "data": {}
    }
  }
}
