import React, {FC, memo} from "react";
import {useSelectedSources} from "../../../hooks/useApp";
import EmptyFormBox from "./EmptyFormBox";
import Form from "./Form";

const LeadForm: FC = () => {

    const [sources] = useSelectedSources()

    const {provider} = sources

    if (!provider)
        return <EmptyFormBox/>

    return (
        <>
            <Form type={provider}/>
        </>
    )
}

export default memo(LeadForm)