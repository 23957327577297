import React, {memo} from "react";
import HistoryRow from "./HistoryRow";
import {
    Box,
    Divider,
    Grid,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip
} from "@material-ui/core";
import {useHistory} from "../../../hooks/useApp";
import DeleteIcon from "@material-ui/icons/Delete";

const HistoryTable = () => {
    const { data, actions:{clear} } = useHistory()

    return (
        <div style={{height: "100%",display:"flex", flexDirection:"column"}}>
            <div style={{paddingLeft: 15, paddingRight: 15}}>
                <Grid container justifyContent="space-between" alignItems="center">
                    <Grid item>
                        <strong>History</strong>
                    </Grid>
                    <Grid item>
                        <Tooltip title="Clear history">
                            <IconButton aria-label="clear history" onClick={()=>{clear()}}>
                                <DeleteIcon />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
            </div>
            <Divider />
            <div style={{paddingLeft:15, paddingRight:15, overflow:"auto", flex:1}}>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell />
                                <TableCell><strong>Environment</strong></TableCell>
                                <TableCell><strong>Provider</strong></TableCell>
                                <TableCell><strong>Source Title</strong></TableCell>
                                <TableCell><strong>Source ID</strong></TableCell>
                                <TableCell align="right"><strong>Request Time</strong></TableCell>
                                <TableCell />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.map((item, index) => <HistoryRow key={index} {...item} />)}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </div>
    )
}

export default memo(HistoryTable)