{
  "$schema": "http://json-schema.org/draft-06/schema#",
  "title": "NerdWallet",
  "type": "object",
  "items": {
    "formSchema": {
      "type": "object",
      "properties": {
        "request": {
          "type": "object",
          "properties":{
            "type": {
              "type": "string",
              "enum": [
                "xml",
                "json",
                "urlencoded"
              ]
            }
          }
        },
        "data": {
          "title": "Form Data",
          "type": "object",
          "properties": {
            "firstName": {
              "title": "First name",
              "type": "string"
            },
            "lastName": {
              "title": "Last name",
              "type": "string"
            },
            "emailAddress": {
              "title": "Email Address",
              "type": "string",
              "format": "email"
            },
            "phoneNumber": {
              "title": "Dey Phone",
              "type": "string",
              "minLength": 10
            },
            "creditScore": {
              "title": "creditScore",
              "type": "number"
            },
            "employmentType": {
              "title": "Employment Type",
              "type": "string",
              "autocomplete":  true,
              "oneOf": [
                {
                  "const": "FULL_TIME",
                  "title": "Full Time"
                },
                {
                  "const": "PART_TIME",
                  "title": "Part Time"
                },
                {
                  "const": "SELF_EMPLOYED",
                  "title": "self employed"
                },
                {
                  "const": "MILITARY",
                  "title": "Military"
                },
                {
                  "const": "RETIRED",
                  "title": "Retired"
                },
                {
                  "const": "UNEMPLOYED",
                  "title": "Unemployed"
                },
                {
                  "const": "OTHER",
                  "title": "Other"
                }
              ]
            },
            "veteran": {
              "title": "Veteran",
              "type": "boolean"
            },

            "state": {
              "title": "State",
              "type": "string",
              "oneOf": [
                {
                  "title": "Alabama",
                  "const": "AL"
                },
                {
                  "title": "Alaska",
                  "const": "AK"
                },
                {
                  "title": "American Samoa",
                  "const": "AS"
                },
                {
                  "title": "Arizona",
                  "const": "AZ"
                },
                {
                  "title": "Arkansas",
                  "const": "AR"
                },
                {
                  "title": "California",
                  "const": "CA"
                },
                {
                  "title": "Colorado",
                  "const": "CO"
                },
                {
                  "title": "Connecticut",
                  "const": "CT"
                },
                {
                  "title": "Delaware",
                  "const": "DE"
                },
                {
                  "title": "District Of Columbia",
                  "const": "DC"
                },
                {
                  "title": "Federated States Of Micronesia",
                  "const": "FM"
                },
                {
                  "title": "Florida",
                  "const": "FL"
                },
                {
                  "title": "Georgia",
                  "const": "GA"
                },
                {
                  "title": "Guam",
                  "const": "GU"
                },
                {
                  "title": "Hawaii",
                  "const": "HI"
                },
                {
                  "title": "Idaho",
                  "const": "ID"
                },
                {
                  "title": "Illinois",
                  "const": "IL"
                },
                {
                  "title": "Indiana",
                  "const": "IN"
                },
                {
                  "title": "Iowa",
                  "const": "IA"
                },
                {
                  "title": "Kansas",
                  "const": "KS"
                },
                {
                  "title": "Kentucky",
                  "const": "KY"
                },
                {
                  "title": "Louisiana",
                  "const": "LA"
                },
                {
                  "title": "Maine",
                  "const": "ME"
                },
                {
                  "title": "Marshall Islands",
                  "const": "MH"
                },
                {
                  "title": "Maryland",
                  "const": "MD"
                },
                {
                  "title": "Massachusetts",
                  "const": "MA"
                },
                {
                  "title": "Michigan",
                  "const": "MI"
                },
                {
                  "title": "Minnesota",
                  "const": "MN"
                },
                {
                  "title": "Mississippi",
                  "const": "MS"
                },
                {
                  "title": "Missouri",
                  "const": "MO"
                },
                {
                  "title": "Montana",
                  "const": "MT"
                },
                {
                  "title": "Nebraska",
                  "const": "NE"
                },
                {
                  "title": "Nevada",
                  "const": "NV"
                },
                {
                  "title": "New Hampshire",
                  "const": "NH"
                },
                {
                  "title": "New Jersey",
                  "const": "NJ"
                },
                {
                  "title": "New Mexico",
                  "const": "NM"
                },
                {
                  "title": "New York",
                  "const": "NY"
                },
                {
                  "title": "North Carolina",
                  "const": "NC"
                },
                {
                  "title": "North Dakota",
                  "const": "ND"
                },
                {
                  "title": "Northern Mariana Islands",
                  "const": "MP"
                },
                {
                  "title": "Ohio",
                  "const": "OH"
                },
                {
                  "title": "Oklahoma",
                  "const": "OK"
                },
                {
                  "title": "Oregon",
                  "const": "OR"
                },
                {
                  "title": "Palau",
                  "const": "PW"
                },
                {
                  "title": "Pennsylvania",
                  "const": "PA"
                },
                {
                  "title": "Puerto Rico",
                  "const": "PR"
                },
                {
                  "title": "Rhode Island",
                  "const": "RI"
                },
                {
                  "title": "South Carolina",
                  "const": "SC"
                },
                {
                  "title": "South Dakota",
                  "const": "SD"
                },
                {
                  "title": "Tennessee",
                  "const": "TN"
                },
                {
                  "title": "Texas",
                  "const": "TX"
                },
                {
                  "title": "Utah",
                  "const": "UT"
                },
                {
                  "title": "Vermont",
                  "const": "VT"
                },
                {
                  "title": "Virgin Islands",
                  "const": "VI"
                },
                {
                  "title": "Virginia",
                  "const": "VA"
                },
                {
                  "title": "Washington",
                  "const": "WA"
                },
                {
                  "title": "West Virginia",
                  "const": "WV"
                },
                {
                  "title": "Wisconsin",
                  "const": "WI"
                },
                {
                  "title": "Wyoming",
                  "const": "WY"
                }
              ]
            },
            "zipCode": {
              "title": "Zip Code",
              "type": "string",
              "minLength": 5
            },
            "propertyType": {
              "title": "Property Type",
              "type": "string",
              "oneOf": [
                {
                  "const": "house",
                  "title": "House"
                },
                {
                  "const": "townhome",
                  "title": "Town Home"
                },
                {
                  "const": "condo",
                  "title": "Condominium"
                },
                {
                  "const": "multi-family",
                  "title": "Multi Family"
                }
              ]
            },
            "propertyUse": {
              "title": "Intended Property Use",
              "type": "string",
              "oneOf": [
                {
                  "const": "primary",
                  "title": "Primary Residence"
                },
                {
                  "const": "secondary",
                  "title": "Second / Vacation Home"
                },
                {
                  "const": "investment",
                  "title": "Investment Property"
                }
              ]
            },
            "propertyValue": {
              "title": "Property Value",
              "type": "number"
            },

            "loanPurpose": {
              "title": "Loan Purpose",
              "type": "string",
              "oneOf": [
                {
                  "const": "purchase",
                  "title": "Purchase"
                },
                {
                  "const": "refinance",
                  "title": "Refinance"
                }
              ]
            },
            "loanAmount": {
              "title": "Loan Amount",
              "type": "number"
            },
            "loanTerm" : {
              "title": "Loan Term",
              "type": "number"
            },
            "loanProgram": {
              "title": "Loan Program",
              "type": "string",
              "enum": [
                "10-year-fixed",
                "15-year-fixed",
                "20-year-fixed",
                "25-year-fixed",
                "30-year-fixed",
                "40-year-fixed",
                "5-1-arm",
                "7-1-arm",
                "3-1-arm"
              ]
            },
            "mortgageBalance": {
              "title": "Mortgage Balance",
              "type": "number"
            },
            "downPayment": {
              "title": "Down Payment",
              "type": "number"
            },
            "fha": {
              "title": "FHA",
              "type": "boolean"
            },

            "apr": {
              "title": "APR",
              "type": "string"
            },
            "credits": {
              "title": "Credits",
              "type": "number"
            },
            "fees": {
              "title": "Fees",
              "type": "number"
            },
            "homeBuyingProcess": {
              "title": "Home Buying Process",
              "type": "string",
              "enum": [
                "JUST_STARTING",
                "NARROWING_OPTIONS",
                "CONSIDERING_HOMES",
                "MAKING_OFFER",
                "UNDER_CONTRACT"
              ]
            },
            "appName": {
              "title": "App Name",
              "type": "string"
            },
            "leadMessage": {
              "title": "Lead Message",
              "type": "string"
            },
            "clickId": {
              "title": "ClickID",
              "type": "string"
            },
            "payment": {
              "type": "object",
              "properties": {
                "total": {
                  "title": "Payment Total",
                  "type": "number"
                },
                "insurance": {
                  "title": "Payment Insurance",
                  "type": "number"
                },
                "principalInterest": {
                  "title": "Payment Principal Interest",
                  "type": "number"
                }
              }
            }
          }
        }
      }
    },
    "uiSchema": {
      "type": "VerticalLayout",
      "elements": [
        {
          "type": "Group",
          "label": "Borrower",
          "elements": [
            {
              "type": "HorizontalLayout",
              "elements": [
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/firstName"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/lastName"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/emailAddress"
                }
              ]
            },
            {
              "type": "HorizontalLayout",
              "elements": [
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/phoneNumber"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/creditScore"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/employmentType"
                }
              ]
            },
            {
              "type": "HorizontalLayout",
              "elements": [
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/veteran"
                }
              ]
            }
          ]
        },
        {
          "type": "Group",
          "label": "Property",
          "elements": [
            {
              "type": "HorizontalLayout",
              "elements": [
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/state"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/zipCode"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/propertyType"
                }
              ]
            },
            {
              "type": "HorizontalLayout",
              "elements": [
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/propertyUse"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/propertyValue"
                }
              ]
            }
          ]
        },
        {
          "type": "Group",
          "label": "Loan",
          "elements": [
            {
              "type": "HorizontalLayout",
              "elements": [
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/loanPurpose"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/loanAmount"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/loanTerm"
                }
              ]
            },
            {
              "type": "HorizontalLayout",
              "elements": [
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/loanProgram"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/downPayment"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/mortgageBalance"
                }
              ]
            },
            {
              "type": "HorizontalLayout",
              "elements": [
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/fha"
                }
              ]
            }
          ]
        },
        {
          "type": "Group",
          "label": "Other",
          "elements": [
            {
              "type": "HorizontalLayout",
              "elements": [
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/apr"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/credits"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/fees"
                }
              ]
            },
            {
              "type": "HorizontalLayout",
              "elements": [
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/homeBuyingProcess"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/appName"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/leadMessage"
                }
              ]
            },
            {
              "type": "HorizontalLayout",
              "elements": [
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/clickId"
                }
              ]
            },
            {
              "type": "HorizontalLayout",
              "elements": [
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/payment/properties/total"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/payment/properties/insurance"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/payment/properties/principalInterest"
                }
              ]
            }
          ]
        }
      ]
    },
    "initialData": {
      "request": {
        "requestType": "urlencoded"
      },
      "data": {}
    }
  }
}
