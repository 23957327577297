import {IFormDataZillow, IRandomData} from "../../types/form";

export const transformZillowData = (original: IRandomData): IFormDataZillow => {
  const {
    person: {
      id,
      firstName,
      lastName,
      email,
      phone
    }
  } = original

  return {
    sender: {
      emailAddress:email,
      firstName: firstName,
      lastName: lastName,
      phoneNumber: phone
    }
  }
}