import React, {ComponentProps, createContext, FC, memo} from 'react';
import {ApolloClient, ApolloProvider, InMemoryCache} from "@apollo/client";
import {ThemeProvider} from "@material-ui/core/styles";
import {useAppProvider} from "../hooks/useApp";
import {useNotificationProvider} from "../hooks/useNotification";
import theme from "../theme";

const client = new ApolloClient({
    uri: '/graphql',
    cache: new InMemoryCache()
});

export const AppContext = createContext(null)
export const NotificationContext = createContext(null)

const AppProviders = ({children}: ComponentProps<FC>): JSX.Element => {
    return (
        <ThemeProvider theme={theme}>
            <ApolloProvider client={client}>
                <AppContext.Provider value={useAppProvider()}>
                    <NotificationContext.Provider value={useNotificationProvider()}>
                        {children}
                    </NotificationContext.Provider>
                </AppContext.Provider>
            </ApolloProvider>
        </ThemeProvider>
    )
}

export default memo(AppProviders)