export const transformLeadSourcesData = (original: any) => {
    return original.map((node: any) => ({
        objectId: node.objectId,
        title: node.title,
        provider: node.provider,
        active: node.isActive
    }))
}

export const OBJtoXML = (obj) => {
    const wrapXML = (obj) => {
        let xml = '';
        for (let prop in obj) {
            xml += obj[prop] instanceof Array ? '' : "<" + prop + ">";
            if (obj[prop] instanceof Array) {
                for (var array in obj[prop]) {
                    xml += "<" + prop + ">";
                    xml += wrapXML(new Object(obj[prop][array]));
                    xml += "</" + prop + ">";
                }
            } else if (typeof obj[prop] == "object") {
                xml += wrapXML(new Object(obj[prop]));
            } else {
                xml += obj[prop];
            }
            xml += obj[prop] instanceof Array ? '' : "</" + prop + ">";
        }
        return xml.replace(/<\/?[0-9]+>/g, '')
    }
    return "<xml version=\"1\">" +wrapXML(obj)+ "</xml>"
}