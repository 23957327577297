import React, {memo} from 'react';
import {Route, Routes} from "react-router-dom";
import HomePage from "./components/pages/HomePage";
import SettingsPage from "./components/pages/SettingsPage";
import NoMatchPage from "./components/pages/NoMatchPage";
import CssBaseline from '@material-ui/core/CssBaseline';
import AppProviders from "./components/AppProviders";
import Notifications from "./components/shared/Notifications";
import MainHeader from "./components/MainHeader";

const App = () => {
    return (
        <React.Fragment>
            <CssBaseline/>
            <AppProviders>
                <Notifications/>

                <MainHeader/>
                <Routes>
                    <Route path="/" element={<HomePage/>}/>
                    <Route path="settings" element={<SettingsPage/>}/>

                    <Route path="*" element={<NoMatchPage/>}/>
                </Routes>
            </AppProviders>
        </React.Fragment>
    );
}

export default memo(App);
