import {v4 as uuidv4} from "uuid";
import {IFormDataCreditKarma, IRandomData} from "../../types/form";

export const transformCreditKarmaData = (original: IRandomData): IFormDataCreditKarma => {
  const {
    person: {
      firstName,
      lastName,
      email,
      phone,
      phone2,
      creditProfile,
      isVeteran,
      workingWithRealtor
    },
    property: {
      value,
      type,
      used,
      location: {
        state,
        city,
        zip
      },
      loan: {
        purpose,
        amount,
        cashOut,
        product,
        program
      }
    }
  } = original

  return {
    firstName: firstName,
    lastName: lastName,
    email: email,
    dayPhone: phone,
    eveningPhone: phone2,
    creditProfile: creditProfile,
    servedInMilitary: isVeteran ? "yes" : "no",
    workingWithRealEstate: workingWithRealtor,

    propertyState: state,
    propertyZipCode: zip,
    propertyType: {
      "single_family_detached": "Single Family",
      "townhome": "Town Home",
      "detached_condo": "Condominium",
      "2_unit": "Multi Family",
      "3_unit": "Multi Family",
      "4_unit": "Multi Family",
      "pud": "Multi Family",
      "warrantable_condo_lt_5_stories": "Condominium",
      "warrantable_condo_5_to_8_stories": "Condominium",
      "warrantable_condo_gt_8_stories": "Condominium",
      "non_warrantable_condo_lt_5_stories": "Condominium",
      "non_warrantable_condo_5_to_8_stories": "Condominium",
      "non_warrantable_condo_gt_8_stories": "Condominium",
      "condotel_condo_lt_5_stories": "Condominium",
      "condotel_condo_5_to_8_stories": "Condominium",
      "condotel_condo_gt_8_stories": "Condominium",
      "manufactured": "Manufactured Home",
      "co_op": "Co-Op"
    }[type],
    propertyUse: {
      "primary_residence": "Primary Residence",
      "second_home": "Second / Vacation Home",
      "investment_property": "Investment Property"
    }[used],
    homeValue: value,

    loanPurpose: {
      "purchase": "Purchase",
      "rate_term_refinance": "Refinance",
      "cash_out_refinance": "Refinance"
    }[purpose],
    ...(["10_year_fixed", "15_year_fixed", "20_year_fixed", "30_year_fixed", "1_year_ARM_30_yrs", "2_year_ARM_30_yrs", "3_year_ARM_30_yrs", "5_year_ARM_30_yrs", "7_year_ARM_30_yrs"].includes(product) && {
      loanTerm: {
        "10_year_fixed": "10",
        "15_year_fixed": "15",
        "20_year_fixed": "20",
        "30_year_fixed": "30",
        "1_year_ARM_30_yrs": "1",
        "2_year_ARM_30_yrs": "2",
        "3_year_ARM_30_yrs": "3",
        "5_year_ARM_30_yrs": "5",
        "7_year_ARM_30_yrs": "7"
      }[product]
    }),
    ...(["10_year_fixed", "15_year_fixed", "20_year_fixed", "30_year_fixed", "1_year_ARM_30_yrs", "2_year_ARM_30_yrs", "3_year_ARM_30_yrs", "5_year_ARM_30_yrs", "7_year_ARM_30_yrs"].includes(product) && {
      amortizationType: product.split("_").includes("fixed") ? "Fixed" : "Adjustable"
    }),
    loanType: {
      "fha": "FHA",
      "conventional": "Conventional",
      "va": "VA",
      "rd": "Jumbo"
    }[program],
    loanAmount: amount,
    ...(cashOut && {cashOutAmount: cashOut}),

    refId: uuidv4()
  }
}