export const FORM_TYPES = {
    NERD_WALLET: 'NerdWallet',
    COSTCO: 'Costco',
    LEAD_POPS: 'LeadPops',
    CREDIT_KARMA: 'CreditKarma',
    LENDING_ARCH: 'LendingArch',
    LENDING_TREE: 'LendingTree',
    MORTGAGE_RESEARCH: 'MortgageResearch',
    FREE_RATE_UPDATE: 'FreeRateUpdate',
    RELCU_PROVIDER: 'RelcuProvider',
    ZILLOW: 'Zillow',
}