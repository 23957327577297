import {FORM_TYPES} from "./FormTypes";
import {
    GET_RANDOM_NERD_WALLET,
    GET_RANDOM_COSTCO,
    GET_RANDOM_LEAD_POPS,
    GET_RANDOM_CREDIT_KARMA,
    GET_RANDOM_FREE_RATE_UPDATE,
    GET_RANDOM_LENDING_ARCH,
    GET_RANDOM_LENDING_TREE,
    GET_RANDOM_MORTGAGE_RESEARCH,
    GET_RANDOM_RELCU,
    GET_RANDOM_ZILLOW
} from "../../../../queries";

export const queryByTypes = {
    [FORM_TYPES.NERD_WALLET]: GET_RANDOM_NERD_WALLET,
    [FORM_TYPES.COSTCO]: GET_RANDOM_COSTCO,
    [FORM_TYPES.LEAD_POPS]: GET_RANDOM_LEAD_POPS,
    [FORM_TYPES.CREDIT_KARMA]: GET_RANDOM_CREDIT_KARMA,
    [FORM_TYPES.LENDING_ARCH]: GET_RANDOM_LENDING_ARCH,
    [FORM_TYPES.LENDING_TREE]: GET_RANDOM_LENDING_TREE,
    [FORM_TYPES.MORTGAGE_RESEARCH]: GET_RANDOM_MORTGAGE_RESEARCH,
    [FORM_TYPES.FREE_RATE_UPDATE]: GET_RANDOM_FREE_RATE_UPDATE,
    [FORM_TYPES.RELCU_PROVIDER]: GET_RANDOM_RELCU,
    [FORM_TYPES.ZILLOW]: GET_RANDOM_ZILLOW,
}