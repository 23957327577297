import {v4 as uuidv4} from "uuid";
import {IFormDataNerdWallet, IRandomData} from "../../types/form";

export const transformNerdWalletData = (original: IRandomData): IFormDataNerdWallet => {
  const {
    person: {
      id,
      firstName,
      lastName,
      email,
      phone,
      creditScore,
      employmentStatus,
      isVeteran
    },
    property: {
      value,
      type,
      used,
      location: {
        state,
        zip,
      },
      loan: {
        purpose,
        amount,
        downPayment,
        product,
        program,
        term,
        currentMortgageBalance,
      }
    }
  } = original

  return {
    firstName: firstName,
    lastName: lastName,
    emailAddress: email,
    phoneNumber: phone,
    creditScore: creditScore,
    employmentType: {
      "Not Self-Employed": "FULL_TIME",
      "Self-Employed": "SELF_EMPLOYED",
      "Unemployed": "UNEMPLOYED"
    }[employmentStatus],
    veteran: isVeteran,

    state: state,
    zipCode: zip,
    ...(["single_family_detached", "townhome", "detached_condo", "2_unit", "3_unit", "4_unit"].includes(type) && {
      propertyType: {
        "single_family_detached": "house",
        "townhome": "townhome",
        "detached_condo": "condo",
        "2_unit": "multi-family",
        "3_unit": "multi-family",
        "4_unit": "multi-family"
      }[type]
    }),
    propertyUse: {
      "primary_residence": "primary",
      "second_home": "secondary",
      "investment_property": "investment"
    }[used],
    propertyValue: value,

    loanPurpose: {
      "purchase": "purchase",
      "rate_term_refinance": "refinance",
      "cash_out_refinance": "refinance"
    }[purpose],
    loanAmount: amount,
    loanTerm: term,
    ...(["10_year_fixed", "15_year_fixed", "20_year_fixed", "25_year_fixed", "30_year_fixed", "40_year_fixed", "3_year_ARM_30_yrs", "5_year_ARM_30_yrs", "7_year_ARM_30_yrs"].includes(product) && {
      loanProgram: {
        "10_year_fixed": "10-year-fixed",
        "15_year_fixed": "15-year-fixed",
        "20_year_fixed": "20-year-fixed",
        "25_year_fixed": "25-year-fixed",
        "30_year_fixed": "30-year-fixed",
        "40_year_fixed": "40-year-fixed",
        "3_year_ARM_30_yrs": "3-1-arm",
        "5_year_ARM_30_yrs": "5-1-arm",
        "7_year_ARM_30_yrs": "7-1-arm"
      }[product]
    }),
    ...(currentMortgageBalance && {mortgageBalance: currentMortgageBalance}),
    ...(downPayment && {downPayment: downPayment}),
    fha: program === "fha",

    clickId: uuidv4()
  }
}