import React, {memo} from "react";
import {Grid} from "@material-ui/core";
import {AddEnvironmentButton} from "../../MainHeader/ManageEnvironment";
import {useEnvironmentsList, useSelectedEnvironment, useSelectedSources} from "../../../hooks/useApp";
import _ from "lodash";

const EmptyFormBox = () => {


    return (
        <Grid
            container
            alignItems="center"
            justifyContent="center"
            style={{height: "100%"}}
        >
            <Grid item>
                <Content/>
            </Grid>

        </Grid>
    )
}

const Content = () => {
    const [environmentsList] = useEnvironmentsList()
    const [selectedEnvironment] = useSelectedEnvironment()
    const [selectedSources] = useSelectedSources()

    if (_.isEmpty(environmentsList))
        return <AddEnvironmentButton/>

    if (_.isEmpty(selectedEnvironment))
        return <span>Select one of the Environments.</span>

    if (_.isEmpty(selectedSources))
        return <span>Select one of the Sources.</span>

    return null
}

export default memo(EmptyFormBox)