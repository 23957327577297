import React, {memo} from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

interface TabProps {
    tabs: string[];
    panels: React.ReactNode[];
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

function TabPanel(props: TabPanelProps) {
    const {children, value, index} = props;

    return (
        <div>
            {value === index && children}
        </div>
    );
}

const CustomTabs = (props: TabProps) => {
    const {tabs, panels} = props;

    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };

    return (
        <React.Fragment>
            <Tabs variant="fullWidth" value={value} onChange={handleChange}>
                {tabs.map((item, index) => <Tab key={index} label={item}/>)}
            </Tabs>
            <div style={{marginTop: 15}}>
                {panels.map((item, index) => (
                    <TabPanel value={value} key={index} index={index}>
                        {item}
                    </TabPanel>
                ))}
            </div>
        </React.Fragment>
    );
}

export default memo(CustomTabs)