import {gql} from "@apollo/client";

export const GET_RANDOM_LENDING_TREE = gql`
    query {
        person {
            firstName
            lastName
            email
            phone
            creditScore
            employmentStatus
            annualIncome
            isVeteran
            dob
        }
        property {
            value
            type
            used
            location {
                state
                city
                zip
            }
            loan {
                purpose
                amount
                cashOut
                downPayment
                program
                term
                currentMortgageBalance
            }
        }
    }
`;