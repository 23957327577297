import {gql} from "@apollo/client";

export const GET_RANDOM_RELCU = gql`
    query {
        person {
            relcu {
                borrower {
                    firstName
                    lastName
                    email
                    phone
                    phone2
                    phone3
                    phone4
                    creditProfile
                    creditScore
                    employmentStatus
                    monthlyIncome
                    annualIncome
                    isVeteran
                    dob
                    gender
                    filedBankruptcy
                    workingWithRealtor
                }
                coBorrower {
                    id
                    firstName
                    lastName
                    email
                    phone
                    phone2
                    phone3
                    creditProfile
                    creditScore
                    employmentStatus
                    monthlyIncome
                    annualIncome
                    isVeteran
                    dob
                    gender
                    filedBankruptcy
                    workingWithRealtor
                }
            }
        }
        property {
            value
            tax
            insurance
            type
            used
            location {
                state
                zip
                city
                street
            }
            loan {
                purpose
                amount
                cashOut
                firstPropertyPurchase
                product
                program
                term
                type
                waiveEscrow
                secondaryFinancing
                currentMortgageBalance
            }
        }
    }
`;