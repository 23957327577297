import {gql} from "@apollo/client";

export const GET_RANDOM_NERD_WALLET = gql`
    query {
        person {
            firstName
            lastName
            email
            phone
            creditScore
            employmentStatus
            isVeteran
        }
        property {
            value
            type
            used
            location {
                state
                zip
            }
            loan {
                purpose
                amount
                downPayment
                product
                program
                term
                currentMortgageBalance
            }
        }
    }
`;