import React, {FC, memo} from "react";
import _ from "lodash";
import {useHistory} from "../../../hooks/useApp";
import EmptyHistory from "./EmptyHistory";
import HistoryTable from "./HistoryTable";

const History: FC = () => {
    const { data } = useHistory()

    return (
        <React.Fragment>
            {_.isEmpty(data) ? <EmptyHistory /> : <HistoryTable />}
        </React.Fragment>
    );
}

export default memo(History);