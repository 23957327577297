import React from "react";
import {memo} from "react";
import {useState} from "react";
import {Breadcrumbs} from "@material-ui/core";
import {Chip} from "@material-ui/core";
import {Button} from "@material-ui/core";
import {Collapse} from "@material-ui/core";
import {Container} from "@material-ui/core";
import {Grid} from "@material-ui/core";
import {IconButton} from "@material-ui/core";
import {makeStyles} from "@material-ui/core";
import {Paper} from "@material-ui/core";
import {Table} from "@material-ui/core";
import {TableBody} from "@material-ui/core";
import {TableCell} from "@material-ui/core";
import {TableContainer} from "@material-ui/core";
import {TableHead} from "@material-ui/core";
import {TableRow} from "@material-ui/core";
import {Typography} from "@material-ui/core";
import HomeIcon from '@material-ui/icons/Home';
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import {Link} from "react-router-dom";
import {useEnvironmentsList, useSourcesList} from "../../hooks/useApp";
import _ from "lodash";
import {
    AddEnvironmentButton,
    EditEnvironmentButton,
    DeleteEnvironmentButton,
    ReFetchEnvironmentButton
} from "../MainHeader/ManageEnvironment";


const SettingsPage = () => {
    const [environments] = useEnvironmentsList()

    return (
        <Container style={{marginTop: 30}}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link to="/" style={{color: "inherit"}}>
                            <Button size="small" startIcon={<HomeIcon/>} style={{textTransform: 'none'}}>
                                Home
                            </Button>
                        </Link>
                        <Typography color="textPrimary">
                            Settings
                        </Typography>
                    </Breadcrumbs>
                </Grid>
                <Grid item xs={12}>
                    <Paper style={{paddingTop: 15, paddingLeft: 15, paddingRight: 15}}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="flex-start"
                        >
                            <Grid item>
                                <h3>Environments</h3>
                            </Grid>
                            <Grid item>
                                <AddEnvironmentButton/>
                            </Grid>
                        </Grid>

                        {!_.isEmpty(environments) ? (
                            <TableContainer>
                                <Table aria-label="Environment Table" style={{minWidth: 1100}}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell/>
                                            <TableCell><strong>Name</strong></TableCell>
                                            <TableCell align="right"><strong>Hostname</strong></TableCell>
                                            <TableCell align="right"><strong>Username</strong></TableCell>
                                            <TableCell align="right"><strong>Password</strong></TableCell>
                                            <TableCell align="right"><strong>CreatedAt</strong></TableCell>
                                            <TableCell align="right"><strong>UpdatedAt</strong></TableCell>
                                            <TableCell align="right"/>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {environments.map((item) => <EnvironmentRow key={item.id} environment={item}/>)}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        ) : (
                            <div style={{textAlign: "center", padding: 25}}>
                                There is no added eny environment.
                            </div>
                        )}
                    </Paper>

                </Grid>
            </Grid>
        </Container>
    )
}

const useRowStyles = makeStyles({
    root: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
});

const EnvironmentRow = ({environment}) => {
    const [sources] = useSourcesList()

    const [open, setOpen] = useState(false);

    const classes = useRowStyles();

    return (
        <React.Fragment key={environment.id}>
            <TableRow className={classes.root}>
                <TableCell>
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                    </IconButton>
                </TableCell>
                <TableCell>{environment.name}</TableCell>
                <TableCell align="right">{environment.hostname}</TableCell>
                <TableCell align="right">{environment.username}</TableCell>
                <TableCell align="right">{environment.password}</TableCell>
                <TableCell align="right"
                           width={190}>{new Date(environment.createdAt).toLocaleDateString()} {new Date(environment.createdAt).toLocaleTimeString()}</TableCell>
                <TableCell align="right"
                           width={190}>{new Date(environment.updatedAt).toLocaleDateString()} {new Date(environment.updatedAt).toLocaleTimeString()}</TableCell>
                <TableCell align="right" width={170}>
                    <EditEnvironmentButton environment={environment}/>
                    <DeleteEnvironmentButton environment={environment}/>
                    <ReFetchEnvironmentButton environment={environment}/>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{paddingBottom: 0, paddingTop: 0}} colSpan={8}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Table size="small" aria-label="Sources Table" style={{marginBottom: 15}}>
                            <TableHead>
                                <TableRow>
                                    <TableCell><strong>Title</strong></TableCell>
                                    <TableCell><strong>Provider</strong></TableCell>
                                    <TableCell><strong>Status</strong></TableCell>
                                    <TableCell align="right"><strong>ObjectId</strong></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {_.find(sources, {environmentsID: environment.id})?.sourcesData.map((source) => (
                                    <TableRow key={source.objectId}>
                                        <TableCell component="th" scope="row">{source.title}</TableCell>
                                        <TableCell>{source.provider ?? '---'}</TableCell>
                                        <TableCell>
                                            <Chip
                                                label={source.active ? "Active" : "Inactive"}
                                                color={source.active ? "primary" : "default"}
                                                size="small"
                                                variant="outlined"
                                            />
                                        </TableCell>
                                        <TableCell align="right">{source.objectId}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    )
}

export default memo(SettingsPage)