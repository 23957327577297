import React, { useState} from "react";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import SaveIcon from '@material-ui/icons/Save';
import _ from "lodash";
import {useEnvironmentsList, useSelectedEnvironment, useSelectedSources, useSourcesList} from "../../hooks/useApp";
import {JsonForms as Form} from "@jsonforms/react";
import {materialCells, materialRenderers} from "@jsonforms/material-renderers";
import {v4 as uuidv4} from "uuid";
import {Box, CircularProgress, IconButton, Snackbar, Tooltip} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import ReactJson from "react-json-view";
import {useNotifications} from "../../hooks/useNotification";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import {Alert} from "@material-ui/lab";
import {getLeadSources} from "../../services/ProviderService";
import JsonView from "../shared/JsonView";


const schema = {
    type: "object",
    required: [
        "name",
        "hostname",
        "username",
        "password"
    ],
    properties: {
        name: {
            type: "string",
            minLength: 3,
        },
        hostname: {
            type: "string",
            minLength: 3,
        },
        username: {
            type: "string",
            minLength: 3,
        },
        password: {
            type: "string",
            minLength: 3,
        },
    }
}
const uiSchema = {
    type: "VerticalLayout",
    elements: [
        {
            type: "Control",
            label: "Name",
            scope: "#/properties/name"
        },
        {
            type: "Control",
            label: "Hostname",
            scope: "#/properties/hostname"
        },
        {
            type: "Control",
            label: "Username",
            scope: "#/properties/username"
        },
        {
            type: "Control",
            label: "Password",
            scope: "#/properties/password"
        }
    ]
}

export const AddEnvironmentButton = () => {
    const [open, setOpen] = useState(false)

    return (
        <>
            <Button
                variant="outlined"
                color="primary"
                onClick={() => setOpen(true)}
                startIcon={<AddCircleIcon/>}
            >
                Add Environment
            </Button>
            <FormDialog open={open} onClose={() => setOpen(false)}/>
        </>
    )
}

export const EditEnvironmentButton = ({environment}) => {
    const [open, setOpen] = useState(false)

    return (
        <>
            <Tooltip title="Edit Environment">
                <IconButton aria-label="edit" onClick={() => setOpen(true)}>
                    <EditIcon fontSize="small"/>
                </IconButton>
            </Tooltip>
            <FormDialog open={open} onClose={() => setOpen(false)} environment={environment}/>
        </>
    )
}

export const DeleteEnvironmentButton = ({environment}) => {
    const [open, setOpen] = useState(false)

    return (
        <>
            <Tooltip title="Delete Environment">
                <IconButton aria-label="delete" onClick={() => setOpen(true)}>
                    <DeleteIcon fontSize="small"/>
                </IconButton>
            </Tooltip>
            <ConfirmationDialog open={open} onClose={() => setOpen(false)} environment={environment} />
        </>
    )
}

export const ReFetchEnvironmentButton = ({environment}) => {
    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(false)

    const [environments, setEnvironments] = useEnvironmentsList()
    const [sources, setSources] = useSourcesList()

    const {addNotification} = useNotifications()

    const handleReFetchEnvironment = async () => {
        setLoading(true)
        const {data, error} = await getLeadSources(environment)

        if (!_.isEmpty(error)) {
            addNotification(error)
            console.log(error)
        } else {
            const resEnv = environments
            resEnv[_.indexOf(resEnv, environment)] = {
                ...environment,
                updatedAt: new Date(),
            }
            setEnvironments([...resEnv])

            const resSrc = sources
            resSrc[_.indexOf(sources, _.find(resSrc, {environmentsID: environment.id}))] = {
                environmentsID: environment.id,
                sourcesData: data
            }
            setSources([...resSrc])
        }

        setLoading(false)
        setOpen(true)
    }

    return (
        <>
            <Tooltip title="Re-Fetch Environment">
                <IconButton aria-label="Refresh" onClick={handleReFetchEnvironment} disabled={loading}>
                    {loading ? <CircularProgress size={20} /> : <AutorenewIcon fontSize="small" />}
                </IconButton>
            </Tooltip>
            <Snackbar open={open} autoHideDuration={6000} onClose={()=>setOpen(false)}>
                <Alert onClose={()=>setOpen(false)} severity="success">
                    The Environment has been Re-Fetched successfully!
                </Alert>
            </Snackbar>
        </>
    )
}


const FormDialog = ({open, onClose, environment = {}}) => {

    const [formData, setFormData] = useState(environment);
    const [loading, setLoading] = useState(false)
    const [errors, setErrors] = useState([]);
    const [validationMode, setValidationMode] = useState<'ValidateAndShow' | 'NoValidation'>('NoValidation');

    const [environments, setEnvironments] = useEnvironmentsList()
    const [sources, setSources] = useSourcesList()

    const {addNotification} = useNotifications()

    const handleAdd = async () => {
        setValidationMode("ValidateAndShow")

        const isValid = _.isEmpty(errors)
        const isDuplicate = _.some(environments, formData)

        if (isDuplicate) {
            console.log("The Environment already exist!")
        }

        if (!isValid) {
            console.log(errors)
        }


        if (isValid && !isDuplicate) {
            setLoading(true)
            const {data, error} = await getLeadSources(formData)

            if (!_.isEmpty(error)) {
                addNotification(error)
                console.log(error)
            } else {
                const id = uuidv4()

                setSources([
                    ...sources,
                    {
                        environmentsID: id,
                        sourcesData: data
                    }
                ])
                setEnvironments([
                    ...environments,
                    {
                        id: id,
                        createdAt: new Date(),
                        updatedAt: new Date(),
                        ...formData
                    }
                ])

                setValidationMode("NoValidation")
                setFormData({})
                onClose()
            }

            setLoading(false)
        }
    };

    const handleUpdate = async () => {
        const isValid = _.isEmpty(errors)
        const isExist = _.some(environments, environment)

        if (!isExist) {
            console.log("The Environment object is not exist on collection!", environments, formData)
        }

        if (!isValid) {
            console.log(errors)
        }

        if (isValid && isExist) {
            setLoading(true)
            const {data, error} = await getLeadSources(formData)

            if (!_.isEmpty(error)) {
                addNotification(error)
                console.log(error)
            } else {

                const resEnv = environments
                resEnv[_.indexOf(resEnv, environment)] = {
                    ...environment,
                    ...formData,
                    updatedAt: new Date(),
                }
                setEnvironments([...resEnv])


                const resSrc = sources
                resSrc[_.indexOf(sources, _.find(resSrc, {environmentsID: environment.id}))] = {
                    environmentsID: environment.id,
                    sourcesData: data
                }
                setSources([...resSrc])

                setValidationMode("NoValidation")
                onClose()
            }

            setLoading(false)
        }
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>{_.isEmpty(environment) ? "Add" : "Edit"} Environment</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    The environment will be {_.isEmpty(environment) ? "added" : "updated"} only if it receives a
                    response successfully
                </DialogContentText>
                <Form
                    schema={schema}
                    uischema={uiSchema}
                    data={formData}
                    renderers={materialRenderers}
                    cells={materialCells}
                    validationMode={validationMode}
                    readonly={loading}
                    onChange={({data, errors}) => {
                        setFormData(data)
                        setErrors(errors)
                    }}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} size="small" color="primary" variant="outlined">Cancel</Button>
                {_.isEmpty(environment) ?
                    <Button
                        onClick={handleAdd}
                        size="small"
                        color="primary"
                        variant="outlined"
                        disabled={loading}
                        startIcon={loading ? <CircularProgress size={20} /> : <AddCircleIcon />}
                    >Add</Button> :
                    <Button
                        onClick={handleUpdate}
                        size="small"
                        color="primary"
                        variant="outlined"
                        disabled={loading}
                        startIcon={loading ? <CircularProgress size={20} /> : <SaveIcon />}
                    >Update</Button>}
            </DialogActions>
        </Dialog>
    )
}

const ConfirmationDialog = ({open, onClose, environment}) => {
    const [environments, setEnvironments] = useEnvironmentsList()
    const [sources, setSources] = useSourcesList()
    const [selectedEnvironment, setSelectedEnvironment] = useSelectedEnvironment()
    const [selectedSources, setSelectedSources] = useSelectedSources()

    const handleDelete = () => {
        if(_.isEqual(environment, selectedEnvironment)){
            setSelectedEnvironment({})
            setSelectedSources({})
        }

        setSources(_.filter(sources, item => item.environmentsID !== environment.id))
        setEnvironments(_.filter(environments, (item) => !_.isEqual(item,environment)))
        onClose();
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
        >
            <DialogTitle>Delete Environment</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Are you sure you want to delete this environment?
                </DialogContentText>
                <JsonView data={environment} />
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={onClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleDelete} color="primary">
                    Delete
                </Button>
            </DialogActions>
        </Dialog>
    )
}