{
  "$schema": "http://json-schema.org/draft-06/schema#",
  "title": "LendingArch",
  "type": "object",
  "items": {
    "formSchema": {
      "type": "object",
      "properties": {
        "request": {
          "type": "object",
          "properties":{
            "type": {
              "type": "string",
              "enum": [
                "xml",
                "json",
                "urlencoded"
              ]
            }
          }
        },
        "data": {
          "title": "Form Data",
          "type": "object",
          "properties": {
            "first_name": {
              "title": "First name",
              "type": "string"
            },
            "last_name": {
              "title": "Last name",
              "type": "string"
            },
            "email_address": {
              "title": "Email Address",
              "type": "string",
              "format": "email"
            },
            "phone_alt": {
              "title": "Phone Alt",
              "type": "string",
              "minLength": 10
            },
            "phone": {
              "title": "Phone",
              "type": "string",
              "minLength": 10
            },
            "phone_home": {
              "title": "Home Phone",
              "type": "string",
              "minLength": 10
            },
            "credit_profile": {
              "title": "Credit Profile",
              "type": "string",
              "autocomplete":  true,
              "enum": [
                "Excellent",
                "Very Good",
                "Good" ,
                "Fair" ,
                "Poor"
              ]
            },
            "credit_score": {
              "title": "Credit Score",
              "type": "number"
            },

            "state": {
              "title": "State",
              "type": "string",
              "oneOf": [
                {
                  "title": "Alabama",
                  "const": "AL"
                },
                {
                  "title": "Alaska",
                  "const": "AK"
                },
                {
                  "title": "American Samoa",
                  "const": "AS"
                },
                {
                  "title": "Arizona",
                  "const": "AZ"
                },
                {
                  "title": "Arkansas",
                  "const": "AR"
                },
                {
                  "title": "California",
                  "const": "CA"
                },
                {
                  "title": "Colorado",
                  "const": "CO"
                },
                {
                  "title": "Connecticut",
                  "const": "CT"
                },
                {
                  "title": "Delaware",
                  "const": "DE"
                },
                {
                  "title": "District Of Columbia",
                  "const": "DC"
                },
                {
                  "title": "Federated States Of Micronesia",
                  "const": "FM"
                },
                {
                  "title": "Florida",
                  "const": "FL"
                },
                {
                  "title": "Georgia",
                  "const": "GA"
                },
                {
                  "title": "Guam",
                  "const": "GU"
                },
                {
                  "title": "Hawaii",
                  "const": "HI"
                },
                {
                  "title": "Idaho",
                  "const": "ID"
                },
                {
                  "title": "Illinois",
                  "const": "IL"
                },
                {
                  "title": "Indiana",
                  "const": "IN"
                },
                {
                  "title": "Iowa",
                  "const": "IA"
                },
                {
                  "title": "Kansas",
                  "const": "KS"
                },
                {
                  "title": "Kentucky",
                  "const": "KY"
                },
                {
                  "title": "Louisiana",
                  "const": "LA"
                },
                {
                  "title": "Maine",
                  "const": "ME"
                },
                {
                  "title": "Marshall Islands",
                  "const": "MH"
                },
                {
                  "title": "Maryland",
                  "const": "MD"
                },
                {
                  "title": "Massachusetts",
                  "const": "MA"
                },
                {
                  "title": "Michigan",
                  "const": "MI"
                },
                {
                  "title": "Minnesota",
                  "const": "MN"
                },
                {
                  "title": "Mississippi",
                  "const": "MS"
                },
                {
                  "title": "Missouri",
                  "const": "MO"
                },
                {
                  "title": "Montana",
                  "const": "MT"
                },
                {
                  "title": "Nebraska",
                  "const": "NE"
                },
                {
                  "title": "Nevada",
                  "const": "NV"
                },
                {
                  "title": "New Hampshire",
                  "const": "NH"
                },
                {
                  "title": "New Jersey",
                  "const": "NJ"
                },
                {
                  "title": "New Mexico",
                  "const": "NM"
                },
                {
                  "title": "New York",
                  "const": "NY"
                },
                {
                  "title": "North Carolina",
                  "const": "NC"
                },
                {
                  "title": "North Dakota",
                  "const": "ND"
                },
                {
                  "title": "Northern Mariana Islands",
                  "const": "MP"
                },
                {
                  "title": "Ohio",
                  "const": "OH"
                },
                {
                  "title": "Oklahoma",
                  "const": "OK"
                },
                {
                  "title": "Oregon",
                  "const": "OR"
                },
                {
                  "title": "Palau",
                  "const": "PW"
                },
                {
                  "title": "Pennsylvania",
                  "const": "PA"
                },
                {
                  "title": "Puerto Rico",
                  "const": "PR"
                },
                {
                  "title": "Rhode Island",
                  "const": "RI"
                },
                {
                  "title": "South Carolina",
                  "const": "SC"
                },
                {
                  "title": "South Dakota",
                  "const": "SD"
                },
                {
                  "title": "Tennessee",
                  "const": "TN"
                },
                {
                  "title": "Texas",
                  "const": "TX"
                },
                {
                  "title": "Utah",
                  "const": "UT"
                },
                {
                  "title": "Vermont",
                  "const": "VT"
                },
                {
                  "title": "Virgin Islands",
                  "const": "VI"
                },
                {
                  "title": "Virginia",
                  "const": "VA"
                },
                {
                  "title": "Washington",
                  "const": "WA"
                },
                {
                  "title": "West Virginia",
                  "const": "WV"
                },
                {
                  "title": "Wisconsin",
                  "const": "WI"
                },
                {
                  "title": "Wyoming",
                  "const": "WY"
                }
              ]
            },
            "property_city": {
              "title": "City",
              "type": "string"
            },
            "zip_code": {
              "title": "Zip Code",
              "type": "string",
              "minLength": 5
            },
            "property_address": {
              "title": "Zip Code",
              "type": "string"
            },
            "property_type": {
              "title": "Property Type",
              "type": "string",
              "enum": [
                "Single Family Home",
                "Townhome",
                "Multi-Family Home",
                "Condominium",
                "Mobile-Manufactured Home"
              ]
            },
            "intended_use": {
              "title": "Property Use",
              "type": "string",
              "enum": [
                "Primary Residence",
                "Second Home",
                "Investment Property"
              ]
            },
            "purchase_price": {
              "title": "Purchase Price",
              "type": "number"
            },
            "existing_home_value": {
              "title": "Existing Home Value",
              "type": "number"
            },

            "loan_purpose": {
              "title": "Loan Purpose",
              "type": "string",
              "enum": [
                "Purchase" ,
                "Refinance"
              ]
            },
            "loan_type" : {
              "title": "Loan Type",
              "type": "string",
              "enum": [
                "Conv",
                "VA"
              ]
            },
            "mortgage_balance": {
              "title": "Mortgage Balance",
              "type": "number"
            },

            "interest_rate": {
              "title": "interest Rate",
              "type": "string"
            },
            "lead_price": {
              "title": "Lead Price",
              "type": "string"
            }
          }
        }
      }
    },
    "uiSchema": {
      "type": "VerticalLayout",
      "elements": [
        {
          "type": "Group",
          "label": "Borrower",
          "elements": [
            {
              "type": "HorizontalLayout",
              "elements": [
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/first_name"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/last_name"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/email_address"
                }
              ]
            },
            {
              "type": "HorizontalLayout",
              "elements": [
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/phone_alt"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/phone"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/phone_home"
                }
              ]
            },
            {
              "type": "HorizontalLayout",
              "elements": [
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/credit_profile"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/credit_score"
                }
              ]
            }
          ]
        },
        {
          "type": "Group",
          "label": "Property",
          "elements": [
            {
              "type": "HorizontalLayout",
              "elements": [
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/state"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/property_city"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/zip_code"
                }
              ]
            },
            {
              "type": "HorizontalLayout",
              "elements": [
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/property_address"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/property_type"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/intended_use"
                }
              ]
            },
            {
              "type": "HorizontalLayout",
              "elements": [
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/purchase_price"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/existing_home_value"
                }
              ]
            }
          ]
        },
        {
          "type": "Group",
          "label": "Loan",
          "elements": [
            {
              "type": "HorizontalLayout",
              "elements": [
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/loan_purpose"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/loan_type"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/mortgage_balance"
                }
              ]
            }
          ]
        },
        {
          "type": "Group",
          "label": "Other",
          "elements": [
            {
              "type": "HorizontalLayout",
              "elements": [
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/interest_rate"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/lead_price"
                }
              ]
            }
          ]
        }
      ]
    },
    "initialData": {
      "request": {
        "requestType": "urlencoded"
      },
      "data": {}
    }
  }
}