import {v4 as uuidv4} from "uuid";
import {IFormDataLendingArch, IRandomData} from "../../types/form";

export const transformLendingArchData = (original: IRandomData): IFormDataLendingArch => {
  const {
    person: {
      id,
      firstName,
      lastName,
      email,
      phone,
      phone2,
      creditScore,
      creditProfile
    },
    property: {
      value,
      type,
      used,
      location: {
        state,
        city,
        zip,
        street
      },
      loan: {
        purpose,
        program,
        currentMortgageBalance
      }
    }
  } = original

  return {
    first_name: firstName,
    last_name: lastName,
    email_address: email,
    phone: phone,
    phone_home: phone2,
    credit_profile: creditProfile,
    credit_score: creditScore,

    state: state,
    property_city: city,
    zip_code: zip,
    property_address: street,
    property_type: {
      "single_family_detached": "Single Family Home",
      "townhome": "Townhome",
      "detached_condo": "Condominium",
      "2_unit": "Multi-Family Home",
      "3_unit": "Multi-Family Home",
      "4_unit": "Multi-Family Home",
      "pud": "Multi-Family Home",
      "warrantable_condo_lt_5_stories": "Condominium",
      "warrantable_condo_5_to_8_stories": "Condominium",
      "warrantable_condo_gt_8_stories": "Condominium",
      "non_warrantable_condo_lt_5_stories": "Condominium",
      "non_warrantable_condo_5_to_8_stories": "Condominium",
      "non_warrantable_condo_gt_8_stories": "Condominium",
      "condotel_condo_lt_5_stories": "Condominium",
      "condotel_condo_5_to_8_stories": "Condominium",
      "condotel_condo_gt_8_stories": "Condominium",
      "manufactured": "Mobile-Manufactured Home",
      "co_op": "Condominium"
    }[type],
    intended_use: {
      "primary_residence": "Primary Residence",
      "second_home": "Second Home",
      "investment_property": "Investment Property"
    }[used],
    existing_home_value: value,

    loan_purpose: {
      "purchase": "Purchase",
      "rate_term_refinance": "Refinance",
      "cash_out_refinance": "Refinance"
    }[purpose],
    ...(["conventional", "va"].includes(program) && {
      loan_type: {
        "conventional": "Conv",
        "va": "VA"
      }[program]
    }),
    ...(currentMortgageBalance && {mortgage_balance: currentMortgageBalance}),

    lead_price: uuidv4()
  }
}