{
  "$schema": "http://json-schema.org/draft-06/schema#",
  "title": "Costco",
  "type": "object",
  "items": {
    "formSchema": {
      "type": "object",
      "properties": {
        "data": {
          "title": "Form Data",
          "type": "object",
          "properties": {
            "First_Name": {
              "title": "First name",
              "type": "string"
            },
            "Last_Name": {
              "title": "Last name",
              "type": "string"
            },
            "Email_Address": {
              "title": "Email Address",
              "type": "string",
              "format": "email"
            },
            "Day_Phone": {
              "title": "Dey Phone",
              "type": "string",
              "minLength": 10
            },
            "Credit_History": {
              "title": "Credit History",
              "type": "string",
              "autocomplete":  true,
              "enum": [
                "Excellent",
                "Good",
                "Very Good",
                "Fair",
                "Poor"
              ]
            },
            "Credit_Score": {
              "title": "Credit Score",
              "type": "number"
            },
            "Military": {
              "title": "Military",
              "type": "boolean"
            },

            "State": {
              "title": "State",
              "type": "string",
              "oneOf": [
                {
                  "title": "Alabama",
                  "const": "AL"
                },
                {
                  "title": "Alaska",
                  "const": "AK"
                },
                {
                  "title": "American Samoa",
                  "const": "AS"
                },
                {
                  "title": "Arizona",
                  "const": "AZ"
                },
                {
                  "title": "Arkansas",
                  "const": "AR"
                },
                {
                  "title": "California",
                  "const": "CA"
                },
                {
                  "title": "Colorado",
                  "const": "CO"
                },
                {
                  "title": "Connecticut",
                  "const": "CT"
                },
                {
                  "title": "Delaware",
                  "const": "DE"
                },
                {
                  "title": "District Of Columbia",
                  "const": "DC"
                },
                {
                  "title": "Federated States Of Micronesia",
                  "const": "FM"
                },
                {
                  "title": "Florida",
                  "const": "FL"
                },
                {
                  "title": "Georgia",
                  "const": "GA"
                },
                {
                  "title": "Guam",
                  "const": "GU"
                },
                {
                  "title": "Hawaii",
                  "const": "HI"
                },
                {
                  "title": "Idaho",
                  "const": "ID"
                },
                {
                  "title": "Illinois",
                  "const": "IL"
                },
                {
                  "title": "Indiana",
                  "const": "IN"
                },
                {
                  "title": "Iowa",
                  "const": "IA"
                },
                {
                  "title": "Kansas",
                  "const": "KS"
                },
                {
                  "title": "Kentucky",
                  "const": "KY"
                },
                {
                  "title": "Louisiana",
                  "const": "LA"
                },
                {
                  "title": "Maine",
                  "const": "ME"
                },
                {
                  "title": "Marshall Islands",
                  "const": "MH"
                },
                {
                  "title": "Maryland",
                  "const": "MD"
                },
                {
                  "title": "Massachusetts",
                  "const": "MA"
                },
                {
                  "title": "Michigan",
                  "const": "MI"
                },
                {
                  "title": "Minnesota",
                  "const": "MN"
                },
                {
                  "title": "Mississippi",
                  "const": "MS"
                },
                {
                  "title": "Missouri",
                  "const": "MO"
                },
                {
                  "title": "Montana",
                  "const": "MT"
                },
                {
                  "title": "Nebraska",
                  "const": "NE"
                },
                {
                  "title": "Nevada",
                  "const": "NV"
                },
                {
                  "title": "New Hampshire",
                  "const": "NH"
                },
                {
                  "title": "New Jersey",
                  "const": "NJ"
                },
                {
                  "title": "New Mexico",
                  "const": "NM"
                },
                {
                  "title": "New York",
                  "const": "NY"
                },
                {
                  "title": "North Carolina",
                  "const": "NC"
                },
                {
                  "title": "North Dakota",
                  "const": "ND"
                },
                {
                  "title": "Northern Mariana Islands",
                  "const": "MP"
                },
                {
                  "title": "Ohio",
                  "const": "OH"
                },
                {
                  "title": "Oklahoma",
                  "const": "OK"
                },
                {
                  "title": "Oregon",
                  "const": "OR"
                },
                {
                  "title": "Palau",
                  "const": "PW"
                },
                {
                  "title": "Pennsylvania",
                  "const": "PA"
                },
                {
                  "title": "Puerto Rico",
                  "const": "PR"
                },
                {
                  "title": "Rhode Island",
                  "const": "RI"
                },
                {
                  "title": "South Carolina",
                  "const": "SC"
                },
                {
                  "title": "South Dakota",
                  "const": "SD"
                },
                {
                  "title": "Tennessee",
                  "const": "TN"
                },
                {
                  "title": "Texas",
                  "const": "TX"
                },
                {
                  "title": "Utah",
                  "const": "UT"
                },
                {
                  "title": "Vermont",
                  "const": "VT"
                },
                {
                  "title": "Virgin Islands",
                  "const": "VI"
                },
                {
                  "title": "Virginia",
                  "const": "VA"
                },
                {
                  "title": "Washington",
                  "const": "WA"
                },
                {
                  "title": "West Virginia",
                  "const": "WV"
                },
                {
                  "title": "Wisconsin",
                  "const": "WI"
                },
                {
                  "title": "Wyoming",
                  "const": "WY"
                }
              ]
            },
            "ZipCode": {
              "title": "Zip Code",
              "type": "string",
              "minLength": 5
            },
            "City": {
              "title": "City",
              "type": "string"
            },
            "Address": {
              "title": "Address",
              "type": "string"
            },
            "Property_Type": {
              "title": "Property Type",
              "type": "string",
              "enum": [
                "Single Family",
                "Townhouse",
                "Condo 1-4 Story",
                "2 Family",
                "Condo 5-8 Story",
                "Condo 9+ Story",
                "Condominium",
                "3 Family",
                "4 Family",
                "Other"
              ]
            },
            "Intended_Property_Use": {
              "title": "Intended Property Use",
              "type": "string",
              "enum": [
                "Primary Residence",
                "Second Home",
                "Investment Property"
              ]
            },
            "Home_Value": {
              "title": "Home Value",
              "type": "number"
            },

            "Program": {
              "title": "Program",
              "type": "string",
              "enum": [
                "Conf 15 Yr Fixed",
                "Conf 20 Yr Fixed",
                "Conf 30 Yr Fixed",
                "Non Conf 30 Yr Fixed",
                "Non Conf 15 Yr Fixed",
                "Govt FHA 30 Yr Fixed",
                "Govt FHA Jumbo 30 Yr Fixed",
                "Govt VA Jumbo 30 Yr Fixed",
                "Govt VA 30 Yr Fixed",
                "Conf 30 Yr SOFR ARM 5/6 mo",
                "Conf 30 Yr SOFR ARM 7/6 mo",
                "Agency FNMA Jumbo 30 Yr  Fixed",
                "Jumbo"
              ]
            },
            "Mortgage_Type": {
              "title": "Mortgage Type",
              "type": "string",
              "enum": [
                "FHA",
                "Conventional",
                "VA"
              ]
            },
            "Term": {
              "title": "Term",
              "type": "number",
              "enum": [
                480,
                360,
                300,
                240,
                180,
                120
              ]
            },
            "Loan_Amount": {
              "title": "Loan Amount",
              "type": "number"
            },
            "Loan_Type": {
              "title": "Loan Type",
              "type": "string",
              "enum": [
                "Purchase",
                "Rate-Term Refinance",
                "Cash-Out Refinance"
              ]
            },
            "Mortgage_Balance": {
              "title": "Mortgage Balance",
              "type": "number"
            },
            "Cash_Out": {
              "title": "Cash Out",
              "type": "number"
            },
            "Down_Payment": {
              "title": "Down Payment",
              "type": "number"
            },

            "CampaignId": {
              "title": "Campaign Id",
              "type": "string"
            },
            "Lead Id": {
              "title": "Lead Id",
              "type": "string"
            },
            "Exclusivity": {
              "title": "Exclusivity",
              "type": "string",
              "enum": [
                "Non-Exclusive",
                "Exclusive",
                "Semi-Exclusive"
              ]
            },
            "Memberships_ID": {
              "title": "Memberships ID",
              "type": "string"
            },
            "Costco_Membership_Type": {
              "title": "Costco Membership Type",
              "type": "string",
              "enum": [
                "Executive",
                "Non-Member",
                "Business Goldstar",
                "Business Executive",
                "Goldstar (Unverified)",
                "Executive (Unverified)",
                "Goldstar",
                "Executive Goldstar Primary",
                "Non-Member (Not Validated)",
                "Non-Executive (Not Validated)",
                "Goldstar Primary",
                "Non-Executive (Unverified)"
              ]
            },
            "Client": {
              "title": "Client",
              "type": "string"
            },
            "XmlResponse": {
              "title": "XmlResponse",
              "type": "string"
            },
            "AcrossCampaigns": {
              "title": "Across Campaigns",
              "type": "string"
            },
            "LTV": {
              "title": "LTV",
              "type": "string"
            },
            "Interest_Rate": {
              "title": "Interest Rate",
              "type": "string"
            },
            "APR": {
              "title": "APR",
              "type": "string"
            },
            "Fees": {
              "title": "Fees",
              "type": "string"
            },
            "Points": {
              "title": "Points",
              "type": "string"
            },
            "Monthly_Principal_and_Interest_payment": {
              "title": "Monthly Principal and Interest payment",
              "type": "string"
            }
          }
        }
      }
    },
    "uiSchema": {
      "type": "VerticalLayout",
      "elements": [
        {
          "type": "Group",
          "label": "Borrower",
          "elements": [
            {
              "type": "HorizontalLayout",
              "elements": [
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/First_Name"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/Last_Name"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/Email_Address"
                }
              ]
            },
            {
              "type": "HorizontalLayout",
              "elements": [
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/Day_Phone"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/Credit_History"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/Credit_Score"
                }
              ]
            },
            {
              "type": "HorizontalLayout",
              "elements": [
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/Military"
                }
              ]
            }
          ]
        },
        {
          "type": "Group",
          "label": "Property",
          "elements": [
            {
              "type": "HorizontalLayout",
              "elements": [
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/State"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/ZipCode"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/City"
                }
              ]
            },
            {
              "type": "HorizontalLayout",
              "elements": [
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/Address"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/Property_Type"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/Intended_Property_Use"
                }
              ]
            },
            {
              "type": "HorizontalLayout",
              "elements": [
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/Home_Value"
                }
              ]
            }
          ]
        },
        {
          "type": "Group",
          "label": "Loan",
          "elements": [
            {
              "type": "HorizontalLayout",
              "elements": [
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/Program"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/Mortgage_Type"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/Term"
                }
              ]
            },
            {
              "type": "HorizontalLayout",
              "elements": [
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/Loan_Amount"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/Loan_Type"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/Mortgage_Balance"
                }
              ]
            },
            {
              "type": "HorizontalLayout",
              "elements": [
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/Cash_Out"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/Down_Payment"
                }
              ]
            }
          ]
        },
        {
          "type": "Group",
          "label": "Other",
          "elements": [
            {
              "type": "HorizontalLayout",
              "elements": [
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/CampaignId"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/Lead Id"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/Exclusivity"
                }
              ]
            },
            {
              "type": "HorizontalLayout",
              "elements": [
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/Memberships_ID"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/Costco_Membership_Type"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/Client"
                }
              ]
            },
            {
              "type": "HorizontalLayout",
              "elements": [
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/XmlResponse"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/AcrossCampaigns"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/LTV"
                }
              ]
            },
            {
              "type": "HorizontalLayout",
              "elements": [
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/Interest_Rate"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/APR"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/Fees"
                }
              ]
            },
            {
              "type": "HorizontalLayout",
              "elements": [
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/Points"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/data/properties/Monthly_Principal_and_Interest_payment"
                }
              ]
            }
          ]
        }
      ]
    },
    "initialData": {
      "request": {
        "requestType": "urlencoded"
      },
      "data": {}
    }
  }
}