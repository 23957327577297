import React, {FC, memo} from "react";
import ReactJson from "react-json-view";

interface JsonViewProps {
    data: object,

    onChange?(data): void;
}

const JsonView: FC<JsonViewProps> = ({data, onChange}) => {

    return (
        <ReactJson
            style={{
                padding: 10,
                borderRadius: 5,
                boxShadow: "5px 3px 10px #888888",
                width: "100%",
                maxWidth: 800,
                overflow: "auto",
                marginTop: 15,
                marginBottom: 15,
                marginLeft: "auto",
                marginRight: "auto"
            }}
            name={false}
            displayObjectSize={false}
            displayDataTypes={false}
            enableClipboard={false}
            theme={"monokai"}
            src={data}
            onEdit={(edit) => onChange({data: edit.updated_src})}
            onAdd={(edit) => onChange({data: edit.updated_src})}
            onDelete={(edit) => onChange({data: edit.updated_src})}
        />
    )
}

export default memo(JsonView)