import {v4 as uuidv4} from "uuid";
import {IFormDataLendingTree, IRandomData} from "../../types/form";

export const transformLendingTreeData = (original: IRandomData): IFormDataLendingTree => {
  const {
    person: {
      id,
      firstName,
      lastName,
      email,
      phone,
      creditScore,
      employmentStatus,
      annualIncome,
      isVeteran,
      dob
    },
    property: {
      value,
      type,
      used,
      location: {
        state,
        city,
        zip
      },
      loan: {
        purpose,
        amount,
        cashOut,
        downPayment,
        program,
        term,
        currentMortgageBalance
      }
    }
  } = original

  let creditRating = "1"
  // "1": "excellent"
  // "2": "good",
  // "3": "poor",
  // "4": "very_poor"
  if (creditScore >= 781 && creditScore <= 850)
    creditRating = "1"

  if (creditScore >= 601 && creditScore <= 780)
    creditRating = "2"

  if (creditScore >= 500 && creditScore <= 600)
    creditRating = "3"

  if (creditScore >= 300 && creditScore <= 499)
    creditRating = "4"

  const [month, date, year] = dob.split('/');

  return {
    ConsumerContactInformation: {
      FirstName: firstName,
      LastName: lastName,
      EmailAddress: {value: email},
      ContactPhone: {value: phone}
    },
    ConsumerProfileInformation: {
      DateOfBirth: `${year}-${month.toString().padStart(2, '0')}-${date.toString().padStart(2, '0')}`,
      IsMilitary: isVeteran ? "Y" : "N",
      IsFHAEligible: {
        "fha": "Yes",
        "conventional": "No",
        "va": "No",
        "rd": "No"
      }[program],
      IsVALoan: {
        "fha": "No",
        "conventional": "No",
        "va": "Yes",
        "rd": "No"
      }[program],
      Credit: {
        AssignedCreditValue: creditScore,
        SelfCreditRating: {Id: creditRating}
      },
      ProductProfileInformation: {
        AnnualIncome: annualIncome,
        EmploymentStatus: {
          Id: {
            "Self-Employed": "3",
            "Not Self-Employed": "2",
            "Unemployed": "4"
          }[employmentStatus]
        }
      },
    },

    ProductInformation: {
      PropertyInformation: {
        PropertyState: state,
        PropertyZip: zip,
        PropertyCity: city,
        PropertyValue: value,
        PropertyType: {
          Id: {
            "single_family_detached": "1",
            "townhome": "0",
            "detached_condo": "0",
            "2_unit": "5",
            "3_unit": "0",
            "4_unit": "0",
            "pud": "0",
            "warrantable_condo_lt_5_stories": "3",
            "warrantable_condo_5_to_8_stories": "0",
            "warrantable_condo_gt_8_stories": "0",
            "non_warrantable_condo_lt_5_stories": "4",
            "non_warrantable_condo_5_to_8_stories": "0",
            "non_warrantable_condo_gt_8_stories": "0",
            "condotel_condo_lt_5_stories": "0",
            "condotel_condo_5_to_8_stories": "0",
            "condotel_condo_gt_8_stories": "0",
            "manufactured": "10",
            "co_op": "6"
          }[type]
        },
        PropertyUse: {
          Id: {
            "primary_residence": "1",
            "second_home": "2",
            "investment_property": "3"
          }[used]
        }
      }
    },

    LoanInformation: {
      LoanRequestType: {
        Id: {
          "purchase": "0",
          "rate_term_refinance": "1",
          "cash_out_refinance": "1"
        }[purpose]
      },
      LoanAmount: amount,
      ...(currentMortgageBalance && {FirstMortgageBalance: currentMortgageBalance}),
      Term: term,
      ...(cashOut && {CashOut: cashOut}),
      ...(downPayment && {DownPayment: downPayment}),
    },


    PartnerProfileInformation: {
      FilterRoutingID: uuidv4()
    },
    TrackingNumber: uuidv4()
  }
}