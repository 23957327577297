import {postLeadForm}                                           from "../services/ProviderService";
import { SourceType }                                           from "../types/global";
import { EnvironmentType }                                      from "../types/global";
import {useHistory, useSelectedEnvironment, useSelectedSources} from "./useApp";
import {useNotifications}                                       from "./useNotification";
import {IFormData}                                              from "../types/form";

export const useFormControl = () => {
    const [selectedEnvironment] = useSelectedEnvironment()
    const [selectedSources] = useSelectedSources()

    const {actions: {add: addHistory}} = useHistory()
    const {addNotification} = useNotifications()


    const submitLeadForm = async (
        formData: IFormData,
        environment: EnvironmentType = selectedEnvironment,
        sources: SourceType = selectedSources
    ): Promise<void> => {




        const { req, res, error} = await postLeadForm(formData,sources.objectId,environment)
        if (res) {
            addHistory({
                time: new Date(),
                source: sources,
                environment: environment,
                leadForm: formData,
                request: req,
                response: res
            })
            //TODO update response notification add support response type
            addNotification({
                id: Date.now(),
                type: {
                    "Success":"success",
                    "Duplicate":"warning"
                }[res] ?? "info",
                title: {
                    "Success":"Success",
                    "Duplicate":"Duplicate"
                }[res] ?? "Unknown",
                text: "The Lead Data has been sent Successfully!"
            })
        }

        if (error) {
            addNotification({
                id: Date.now(),
                type: "error",
                title: "Error",
                text: error.toString()
            })
        }

    }

    return {
        submitLeadForm
    }
}
