import {v4 as uuidv4} from "uuid";
import {IFormDataLeadPops, IRandomData} from "../../types/form";

export const transformLeadPopsData = (original: IRandomData): IFormDataLeadPops => {
  const {
    person: {
      id,
      firstName,
      lastName,
      email,
      phone,
      creditProfile,
      employmentStatus,
      monthlyIncome,
      annualIncome,
      filedBankruptcy,
      workingWithRealtor
    },
    property: {
      value,
      type,
      used,
      location: {
        state,
        zip
      },
      loan: {
        purpose,
        amount,
        cashOut,
        downPayment
      }
    }
  } = original

  return {
    firstname: firstName,
    lastname: lastName,
    primaryemail: email,
    primaryphone: phone,
    yourcreditprofile: creditProfile,
    grossmonthlyincome: monthlyIncome,
    grossannualincome: annualIncome,
    employmentstatus: {
      "Unemployed": "Not Employed",
      "Not Self-Employed": "Employed",
      "Self-Employed": "Self Employed"
    }[employmentStatus],
    workingwithrealtor: workingWithRealtor,
    filedbankruptcy: filedBankruptcy,

    state: state,
    enteryourzipcode: zip,
    typeofproperty: {
      "single_family_detached": "Single Family Home",
      "townhome": "Townhome",
      "detached_condo": "Condominium",
      "2_unit": "Multi-Family Home",
      "3_unit": "Multi-Family Home",
      "4_unit": "Multi-Family Home",
      "pud": "Other",
      "warrantable_condo_lt_5_stories": "Other",
      "warrantable_condo_5_to_8_stories": "Other",
      "warrantable_condo_gt_8_stories": "Other",
      "non_warrantable_condo_lt_5_stories": "Other",
      "non_warrantable_condo_5_to_8_stories": "Other",
      "non_warrantable_condo_gt_8_stories": "Other",
      "condotel_condo_lt_5_stories": "Other",
      "condotel_condo_5_to_8_stories": "Other",
      "condotel_condo_gt_8_stories": "Other",
      "manufactured": "Other",
      "co_op": "Other"
    }[type],
    propertyusedfor: {
      "primary_residence": "Primary Home",
      "second_home": "Secondary Home",
      "investment_property": "Rental Property"
    }[used],
    estimatedhomevalue: value,

    typeloan: {
      "purchase": "Home Purchase",
      "rate_term_refinance": "Home Refinance",
      "cash_out_refinance": "Home Refinance"
    }[purpose],
    loanamount: amount,
    ...(cashOut && {additionalcash: cashOut}),
    ...(downPayment && {downpayment: downPayment}),

    utmcampaign: "Random Generation",
    SourceID: uuidv4(),
    SubID: uuidv4(),
    ClickID: uuidv4()
  }
}