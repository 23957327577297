import {v4 as uuidv4} from "uuid";
import {IFormDataCostco, IRandomData} from "../../types/form";

export const transformCostcoData = (original: IRandomData): IFormDataCostco => {
  const {
    person: {
      firstName,
      lastName,
      email,
      phone,
      creditScore,
      creditProfile,
      isVeteran
    },
    property: {
      value,
      type,
      used,
      location: {
        state,
        city,
        zip,
        street
      },
      loan: {
        purpose,
        amount,
        cashOut,
        downPayment,
        product,
        program,
        term,
        currentMortgageBalance,
      }
    }
  } = original

  return {
    First_Name: firstName,
    Last_Name: lastName,
    Email_Address: email,
    Day_Phone: phone,
    Credit_History: creditProfile,
    Credit_Score: creditScore,
    Military: isVeteran,

    State: state,
    ZipCode: zip,
    City: city,
    Address: street,
    Property_Type: {
      "single_family_detached": "Single Family",
      "townhome": "Townhouse",
      "detached_condo": "Condominium",
      "2_unit": "2 Family",
      "3_unit": "3 Family",
      "4_unit": "4 Family",
      "pud": "Other",
      "warrantable_condo_lt_5_stories": "Condo 1-4 Story",
      "warrantable_condo_5_to_8_stories": "Condo 5-8 Story",
      "warrantable_condo_gt_8_stories": "Other",
      "non_warrantable_condo_lt_5_stories": "Other",
      "non_warrantable_condo_5_to_8_stories": "Other",
      "non_warrantable_condo_gt_8_stories": "Other",
      "condotel_condo_lt_5_stories": "Condo 1-4 Story",
      "condotel_condo_5_to_8_stories": "Condo 5-8 Story",
      "condotel_condo_gt_8_stories": "Other",
      "manufactured": "Other",
      "co_op": "Other"
    }[type],
    Intended_Property_Use: {
      "primary_residence": "Primary Residence",
      "second_home": "Second Home",
      "investment_property": "Investment Property"
    }[used],
    Home_Value: value,

    Program: {
      "10_year_fixed": "Jumbo",
      "15_year_fixed": "Conf 15 Yr Fixed",
      "20_year_fixed": "Conf 20 Yr Fixed",
      "25_year_fixed": "Jumbo",
      "30_year_fixed": "Conf 30 Yr Fixed",
      "40_year_fixed": "Jumbo",
      "6_mo_ARM_30_yrs": "Jumbo",
      "1_year_ARM_30_yrs": "Jumbo",
      "2_year_ARM_30_yrs": "Jumbo",
      "3_year_ARM_30_yrs": "Jumbo",
      "5_year_ARM_30_yrs": "Jumbo",
      "7_year_ARM_30_yrs": "Jumbo",
      "10_year_ARM_30_yrs": "Jumbo",
      "5_year_balloon_30_years": "Jumbo",
      "7_year_balloon_30_years": "Jumbo",
      "10_year_balloon_30_years": "Jumbo",
      "other_products": "Jumbo"
    }[product],
    Mortgage_Type: {
      "fha": "FHA",
      "conventional": "Conventional",
      "va": "VA",
      "rd": "VA"
    }[program],
    Term: term,
    Loan_Amount: amount,
    Loan_Type: {
      "purchase": "Purchase",
      "rate_term_refinance": "Rate-Term Refinance",
      "cash_out_refinance": "Cash-Out Refinance"
    }[purpose],
    ...(currentMortgageBalance && {Mortgage_Balance: currentMortgageBalance}),
    ...(cashOut && {Cash_Out: cashOut}),
    ...(downPayment && {Down_Payment: downPayment}),

    "Lead Id": uuidv4()
  }
}